import Vue from 'vue';
import { defineStore } from 'pinia';
import { Product, NotificationsService } from '@watchtowerbenefits/shared-components';
import { uploadRenewalUploadEnhancements } from '@/utils/featureFlags.js';

/* eslint-disable import/no-cycle */
import { useProjectStore } from '@/stores/project.js';
import { useProductStore } from '@/stores/product.js';
// eslint-disable-next-line import/no-cycle

import { config } from '@/utils/config.js';

const apiConfig = {
  baseUrl: config.VUE_APP_API_URL,
  cookieNamespace: config.VUE_APP_COOKIE_NAMESPACE,
};

export const useNotificationsStore = defineStore('notifications', {
  state: () => ({
    alertQuotesTab: {
      text: null,
      type: null,
      visible: false,
      closable: true,
    },
    unreadEdits: null,
    allProductsSubmittedDialogVisible: false,
    reviewAlertDismissed: false,
  }),

  actions: {
    clearAlertsQuotesTab() {
      if (this.alertQuotesTab.visible) {
        this.setAlert({});
      }
    },
    async getEditsNotifications(projectId) {
      const data = await NotificationsService.getEditsNotifications(projectId, 'carrier_portal', apiConfig);

      this.unreadEdits = data.unread_notifications;
    },
    // `product` is passed only when declining/submitting a product.
    // Otherwise, text && type need to be passed.
    setAlertQuotesTab({
      actionType = 'submitted',
      closable,
      products = [],
      text,
      type,
    }) {
      let messageText = text;
      let messageType = type;

      if (products.length) {
        const projectStore = useProjectStore();
        const productNames = products
          .map(({ product_type_name: productName }) => productName)
          .join(', ')
          .replace(/,([^,]*)$/, ' and$1');

        messageText = `Your ${productNames} quote has been submitted to ${projectStore.broker.name}.`;
        messageType = 'success';

        if (actionType === 'declined') {
          messageText = `You have successfully declined to quote ${productNames}.`;
        }

        // rff: uploadRenewalUploadEnhancements - this if statement can be completely removed with the feature flag
        if (!Vue.prototype.$ld?.checkFlags(uploadRenewalUploadEnhancements)) {
          const { isStopLoss } = Product;

          if (isStopLoss(products[0])) {
            const stopLossProducts = useProductStore().products
              .filter((stopLossProduct) => isStopLoss(stopLossProduct));
            const completed = stopLossProducts.filter(({ state }) => ['completed', 'declined'].includes(state));

            // If all the stop loss options have not been declined/completed yet then
            // the warning copy should read`You have submitted X of X Stop loss options to {brokerName}.`
            if (completed.length && completed.length !== stopLossProducts.length) {
              messageText = `You have submitted ${completed.length} of ${stopLossProducts.length} Stop loss options to ${projectStore.broker.name}.`;
            }
          }
        }
      }

      // For now we only want to show a toast instead of an alert if we are on the new Product Table
      if (Vue.prototype.$ld?.checkFlags(uploadRenewalUploadEnhancements) && products.length) {
        this.showMessage(messageText, messageType);
      } else {
        this.setAlert({
          text: messageText,
          type: messageType,
          visible: true,
          closable,
        });
      }
    },
    /**
     * Call the Element message function to display a toast message with specified parameters
     *
     * @param {string} message
     * @param {string} type
     */
    showMessage(message, type) {
      Vue.prototype.$message({
        duration: 6000,
        message,
        showClose: true,
        type,
      });
    },
    /**
     * Set the alertQuotesTab object in the store so that the component can display a custom alert
     *
     * @param {object} alertDetails
     * @param {string} alertDetails.text
     * @param {string} alertDetails.type
     * @param {boolean} alertDetails.visible
     * @param {boolean} alertDetails.closable
     */
    setAlert({
      text = null,
      type = null,
      visible = false,
      closable = true,
    }) {
      this.alertQuotesTab = {
        text,
        type,
        visible,
        closable,
      };
    },
  },
});
