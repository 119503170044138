<template>
  <div
    :class="[
      'broker-update',
      { 'pdf-export-block': isPdfRender }
    ]"
  >
    <TfBadge
      v-if="update.is_new"
      size="small"
      status="success"
      value="new"
    />
    <p v-if="update.object_changes && update.object_changes.due_date">
      <span
        class="label"
        v-text="`Due date updated:`"
      />
      {{ formatSimpleDate(update.object_changes.due_date) }}
    </p>
    <template v-for="createdItem in update.source_changes.created">
      <p :key="`file-${createdItem.id}`">
        <span
          class="label"
          v-text="`New ${createdItem.file_type_mapping} uploaded:`"
        />
        <AppButton
          v-if="!createdItem.deleted"
          :is-loading="currentlyDownloadingSource[createdItem.id]"
          :text="createdItem.file_name"
          size="text"
          tag="a"
          @click="downloadSource(createdItem)"
        />
        <template v-else>
          {{ createdItem.file_name }}
          <span class="download-deleted">(file deleted)</span>
        </template>
      </p>
      <p
        v-if="showDownloadErrorForSource[createdItem.id]"
        :key="`download-error-${createdItem.id}`"
        class="download-error"
      >
        There was a problem downloading the file.
      </p>
    </template>
    <p
      v-for="destroyedItem in update.source_changes.destroyed"
      :key="destroyedItem.id"
    >
      <span
        class="label"
        v-text="`${destroyedItem.file_type_mapping} Deleted:`"
      />
      {{ destroyedItem.file_name }}
    </p>
    <p v-if="updateNotes">
      <span
        class="label"
        v-text="'Update notes:'"
      />
      <span v-html="updateNotes" />
    </p>
    <p class="user-info">
      {{ formatDateAndTime(update.ended_at) }}
      <br>
      {{ userInfo }}
    </p>
  </div>
</template>

<script>
  import { formatSimpleDate, formatDateAndTime } from '@watchtowerbenefits/es-utils-public';

  /**
   * Broker updates
   *
   * @exports BrokerUpdate
   */
  export default {
    name: 'BrokerUpdate',
    props: {
      isPdfRender: {
        default: false,
        type: Boolean,
      },
      update: {
        type: Object,
        default: () => ({
          is_new: false,
          object_changes: {},
        }),
      },
    },
    data: () => ({
      currentlyDownloadingSource: {},
      showDownloadErrorForSource: {},
    }),
    computed: {
      /**
       * Get the user info of the update
       *
       * @returns {string}
       */
      userInfo() {
        const { user } = this.update;
        let userInfo = '';

        if (user.first_name) {
          userInfo += user.first_name;
        }

        if (user.last_name) {
          userInfo += ` ${user.last_name}`;
        }

        if (user.organization && user.organization.name) {
          userInfo += `, ${user.organization.name}`;
        }

        return userInfo;
      },
      /**
       * Get the update notes with line breaks
       *
       * @returns {string}
       */
      updateNotes() {
        return this.update.notes
          ? this.update.notes.replace(/(?:\r\n|\r|\n)/g, '<br>')
          : '';
      },
    },
    methods: {
      formatDateAndTime,
      formatSimpleDate,
      /**
       * On click of the download button we call the getSource call from the source service
       *
       * @param {object} source
       */
      downloadSource(source) {
        this.$set(this.currentlyDownloadingSource, source.id, true);

        this.$TF.SourceService
          .getSource(
            source.id,
            'carrier_portal',
          )
          .then((response) => {
            // call the api to get the data for this file
            const base64String = response.data.source.content.replace(/\s/g, '');
            const byteCharacters = window.atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i += 1) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new window.Blob([byteArray], { type: 'application/octet-stream' });

            this.$set(this.currentlyDownloadingSource, source.id, false);
            this.$set(this.showDownloadErrorForSource, source.id, false);

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, response.data.source.file_file_name);
            } else {
              const downloadLink = document.createElement('a');

              downloadLink.value = 'Download';
              downloadLink.target = '_blank';
              downloadLink.download = response.data.source.file_file_name;
              let blobURL = '';

              blobURL = window.URL.createObjectURL(blob);
              downloadLink.href = blobURL;
              this.$el.appendChild(downloadLink);
              // fire a click event on the anchor
              downloadLink.click();
              downloadLink.remove();
              this.$emit('downloaded');
            }
          })
          .catch(() => {
            this.$set(this.currentlyDownloadingSource, source.id, false);
            this.$set(this.showDownloadErrorForSource, source.id, true);
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .broker-update {
    margin-left: 28px;
    padding: 30px 0;
    border-bottom: 1px solid $tf-extra-light-gray-1;

    &.pdf-export-block {
      width: 100%;
    }
  }

  .tf-button.tf-button--text {
    justify-content: flex-start;

    .pdf-export-block & {
      color: $tf-dark-gray;
    }
  }

  .download-deleted {
    color: $tf-danger;
  }

  .el-alert.alert-new {
    margin: 0;

    .pdf-export-block & {
      display: none;
    }
  }

  .label {
    display: block;
    font-weight: 600;

    .pdf-export-block & {
      font-weight: 700;
    }
  }

  .user-info {
    margin-top: 20px;
    color: $tf-medium-gray;
  }

  p {
    line-height: 22px;
  }

  .download-error {
    margin-top: 0;
    color: $tf-danger;
    font-size: 12px;
  }
</style>
