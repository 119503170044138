// Vue
import Vue from 'vue';
import VueRouter from 'vue-router';
import App from '@/App.vue';
import './assets/styles/main.scss';
import '@watchtowerbenefits/cp-components/dist/style.css';
import filters from '@/utils/filters.js';
import { config as appConfig } from '@/utils/config.js';
import router from '@/router.js';
import { useLaunchDarklyStore } from '@/stores/launchDarkly.js';

// packages
import { Threeflow } from '@watchtowerbenefits/shared-components';
import { initializeSegment, getCookie } from '@watchtowerbenefits/es-utils-public';

import {
  init as SentryInit,
  BrowserTracing as SentryBrowserTracing,
  setContext as SentrySetContext,
  vueRouterInstrumentation as SentryVueRouterInstrumentation,
} from '@sentry/vue';
import LogRocket from 'logrocket';
import { createPinia, PiniaVuePlugin } from 'pinia';
import LoadScript from 'vue-plugin-load-script';
import Icon from 'vue-awesome/components/Icon.vue';
import { Notification } from 'element-ui';

// services
import Interceptor from '@/services/interceptor.js';
import ServiceAccount from '@/services/account.js';
import { getCoreApiStatus } from '@/services/coreApi.js';

// utils
import { initializeLD, parseLDConfig } from '@/utils/launchDarkly.js';

// import icons vue-awesome
import 'vue-awesome/icons/angle-left.js';
import 'vue-awesome/icons/angle-right.js';
import 'vue-awesome/icons/angle-up.js';
import 'vue-awesome/icons/ban.js';
import 'vue-awesome/icons/caret-down.js';
import 'vue-awesome/icons/caret-up.js';
import 'vue-awesome/icons/check.js';
import 'vue-awesome/icons/check-circle.js';
import 'vue-awesome/icons/chevron-down.js';
import 'vue-awesome/icons/chevron-right.js';
import 'vue-awesome/icons/chevron-left.js';
import 'vue-awesome/icons/chevron-up.js';
import 'vue-awesome/icons/clock.js';
import 'vue-awesome/icons/comment.js';
import 'vue-awesome/icons/comments.js';
import 'vue-awesome/icons/copy.js';
import 'vue-awesome/icons/eye.js';
import 'vue-awesome/icons/eye-slash.js';
import 'vue-awesome/icons/exchange-alt.js';
import 'vue-awesome/icons/file-download.js';
import 'vue-awesome/icons/filter.js';
import 'vue-awesome/icons/lock.js';
import 'vue-awesome/icons/paper-plane.js';
import 'vue-awesome/icons/pencil-alt.js';
import 'vue-awesome/icons/plus.js';
import 'vue-awesome/icons/redo.js';
import 'vue-awesome/icons/regular/calendar-alt.js';
import 'vue-awesome/icons/reply.js';
import 'vue-awesome/icons/spinner.js';
import 'vue-awesome/icons/times.js';
import 'vue-awesome/icons/trash.js';
import 'vue-awesome/icons/trash-alt.js';

import { logrocketPlugin } from './utils/piniaPlugins.js';
import { canUseThirdParty } from './utils/general.js';

Interceptor.activate();
Vue.component('Icon', Icon);

Vue.prototype.$notify = Notification;

const {
  VUE_APP_COOKIE_NAMESPACE: cookieNameSpace,
  VUE_APP_ENV: appEnvironment,
  VUE_APP_SEGMENT_TOKEN: segmentToken,
  VITE_APP_NPM_PACKAGE_VERSION: appVersion,
} = appConfig;
// some third party services we only want to run in non-features environments
const isNonFeaturesEnvironment = appConfig.analyticsEnabled(['production', 'staging', 'qa', 'demo']);

Vue.config.productionTip = false;
Vue.use(Threeflow, {
  inactiveLogout: Interceptor.inactiveSignOut,
});

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(VueRouter);

// Allow for easy/on-the-fly loading of scripts for libraries that expect them in the <HEAD>
Vue.use(LoadScript);

pinia.use(logrocketPlugin);

if (isNonFeaturesEnvironment || canUseThirdParty('logrocket')) {
  LogRocket.init('r2e0ub/carrier-site', {
    console: {
      shouldAggregateConsoleErrors: true,
    },
    network: {
      requestSanitizer: (request) => {
        // if the request payload contains 'password'
        if (request?.body?.indexOf('password') !== -1) {
          // Scrub the request body
          // eslint-disable-next-line no-param-reassign
          request.body = null;
        }

        // return the request
        return request;
      },
    },
  });

  LogRocket.getSessionURL((sessionURL) => {
    // send the logrocket url to sentry so we can attach it to tickets
    if (isNonFeaturesEnvironment || canUseThirdParty('sentry')) {
      SentrySetContext('LogRocket', { sessionURL });
    }
    // When you generate the LogRocket session url, we call the Axios Interceptor to pass it on each network call
    Interceptor.addLogRocketURLToHeaders(sessionURL);
  });
}

if (isNonFeaturesEnvironment || canUseThirdParty('sentry')) {
  SentryInit({
    Vue,
    dsn: 'https://464c9a91201b4b98b0de7d0d36da77bc@o97018.ingest.sentry.io/242048',
    tracePropagationTargets: ['threeflow.com', /^\//],
    ignoreErrors: [
      /Object Not Found Matching Id/i,
      /request failed with status code 401/i,
      /network error/i,
    ],
    integrations: [
      new SentryBrowserTracing({
        routingInstrumentation: SentryVueRouterInstrumentation(router),
        logErrors: true,
      }),
    ],
    environment: appEnvironment,
    release: `carrier_ui-${appEnvironment}@${appVersion}`,
    beforeSend: (event, { originalException, captureContext }) => {
      // we want to stop 401 errors from being sent to sentry
      const has401Status = captureContext?.error?.message?.match(/401/)
        || originalException?.message?.match(/401/);

      // returning null discards the event entirely
      return has401Status ? null : event;
    },
  });
}

// segment we still sometimes want to run in features environments for
// testing purposes, however we still send all data to the segment staging
// environment for all features sites
if (isNonFeaturesEnvironment || canUseThirdParty('segment')) {
  initializeSegment(segmentToken);
}

getCoreApiStatus().then((response) => {
  // getCoreApiStatus is the only endpoint that's not parsed by the interceptor, because, when the interceptor fires,
  // Vue isn't initialized yet. So, we slightly double it up and do it here instead.
  const config = parseLDConfig(response);

  initializeLD(config);

  return config;
}).catch(() => {
  throw new Error('Health Check returned not healthy.');
}).finally((launchDarklyConfig) => {
  new Vue({
    pinia,
    router,
    filters,
    /**
     * Once the app is mounted, we add the intercom-loaded class to the body if Intercom is loaded,
     * because we need to accomodate for the Intercom chat widget in some instances.
     */
    mounted() {
      if (isNonFeaturesEnvironment || canUseThirdParty('segment')) {
        /**
         * Polls for the Intercom script to load.
         *
         * @param {Function} callback
         */
        const pollForIntercom = (callback) => {
          let timesChecked = 0;
          const checkIntercom = () => {
            if (typeof window.Intercom === 'function') {
              callback(true);
            } else if (timesChecked < 10) {
              setTimeout(checkIntercom, 500);
              timesChecked += 1;
            } else {
              callback(false);
            }
          };

          checkIntercom();
        };

        pollForIntercom((isLoaded) => {
          if (isLoaded) {
            document.body.classList.add('intercom-loaded');
          }
        });
      }
    },
    /**
     * Once the app is created, we either check to see if the user is signed in
     */
    created() {
      const authToken = getCookie(`${cookieNameSpace}-auth-token`);
      const [matchedRoute] = router.history.current.matched;
      const { projectId } = this.$route.params;
      const launchDarklyStore = useLaunchDarklyStore(pinia);

      if (launchDarklyConfig) {
        launchDarklyStore.config = launchDarklyConfig;
      }

      if (!authToken || window.localStorage.getItem('auth') !== 'true') {
        window.localStorage.removeItem('auth');
        window.localStorage.removeItem('confirmed');

        if (matchedRoute?.meta.requireAuth) {
          this.$router.push({
            name: projectId ? 'ProjectSignIn' : 'SignIn',
            params: { projectId },
          });
        }

        return;
      }

      ServiceAccount.getCurrentUser()
        .then((data) => {
          ServiceAccount.setSignedIn(data);
        })
        .catch(() => {
          this.$router.push({
            name: projectId ? 'ProjectSignOut' : 'SignOut',
            params: { projectId },
          });
        });
    },
    render: (h) => h(App),
  }).$mount('#app');
});

if (window.Cypress) {
  // Add setSignedIn function to window.Cypress as persistMockSignIn so that the mockSignIn command can properly set localStorage and cookies
  window.Cypress.persistMockSignIn = ServiceAccount.setSignedIn;
}
