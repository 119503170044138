<template>
  <ElDialog
    :before-close="onBeforeClose"
    :title="`${product.product_type_name}${product.project_product.alternative
      ? ` - ${product.project_product.label}`
      : ''} plan summary`"
    :visible="visible"
    class="dialog-plan-summary"
    top="70px"
    width="856px"
  >
    <div v-loading="loadingPlanDesign || loadingRateEntry">
      <template v-if="info && rateEntry">
        <img
          class="logo"
          :src="inforceCarrier.logo_url"
          :alt="inforceCarrier.name"
        >
        <section>
          <h3>Plan design</h3>
          <template v-if="planDesignIsPlan">
            <PlanDesignContainer
              v-for="container in planDesignContainers"
              :key="container.id"
              :container="container"
            />
          </template>
          <PlanDesignContainer v-else />
        </section>
        <hr>
        <section>
          <h3>Rates</h3>
          <RateEntryContainer
            v-for="container in rateEntryContainers"
            :key="container.id.join('_')"
            :container="container"
          />
        </section>
      </template>
    </div>
  </ElDialog>
</template>

<script>
  import { mapState, mapActions } from 'pinia';
  import useContainerStore from '@/stores/planDesign/containers.js';
  import PlanDesignContainer from '@/components/PlanDesign/PlanDesignContainer.vue';
  import RateEntryContainer from '@/components/RateEntry/RateEntryContainer.vue';
  import { useRateEntryStore } from '@/stores/rateEntry.js';
  import { useProductStore } from '@/stores/product.js';
  import { usePlanDesignStore } from '@/stores/planDesign.js';
  import { isStopLossProduct } from '@watchtowerbenefits/es-utils-public';

  /**
   * Dialog Plan Summary
   *
   * @exports Modals/PlanSummaryModal
   */
  export default {
    name: 'PlanSummaryModal',
    components: { PlanDesignContainer, RateEntryContainer },
    provide: {
      isPlanSummary: true,
    },
    props: {
      product: {
        type: Object,
        required: true,
        default: () => {},
      },
      visible: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState(useContainerStore, {
        planDesignContainers: 'containers',
      }),
      ...mapState(useRateEntryStore, {
        loadingRateEntry: 'loadingRateEntry',
        rateEntry: 'info',
        rateEntryContainers: 'rateEntryContainers',
      }),
      ...mapState(useProductStore, ['products']),
      ...mapState(usePlanDesignStore, [
        'loadingPlanDesign',
        'planDesignIsPlan',
        'info',
      ]),
      /**
       * gets the inforce carrier
       *
       * @returns {object} - a carrier object
       */
      inforceCarrier() {
        return isStopLossProduct(this.product)
          ? this.products.find((product) => isStopLossProduct(product)
            && !product.project_product.alternative).project_product.inforce_product.carrier
          : this.product.project_product.inforce_product.carrier;
      },
      /**
       * gets the name of the product type
       *
       * @returns {string}
       */
      productTitle() {
        return this.product.project_product.product_type_name;
      },
      /**
       * gets the title of an alternative product or null if no alternative
       *
       * @returns {string|null}
       */
      productAlternativeTitle() {
        return this.product.project_product.alternative
          ? this.product.project_product.label
          : null;
      },
    },
    /**
     * get the plan summary for the given productId
     */
    created() {
      this.getPlanSummary({
        productId: this.product.id,
        productTypeId: this.product.project_product.product_type_id,
      });
    },
    methods: {
      ...mapActions(usePlanDesignStore, ['clearPlanDesign']),
      ...mapActions(useRateEntryStore, ['clearRateEntry']),
      ...mapActions(useProductStore, ['toggleDialogPlanSummary', 'getPlanSummary']),
      /**
       * If you click the 'x' make sure the dialog closes
       *
       * @param {Function} done
       */
      onBeforeClose(done) {
        this.clearRateEntry();
        this.clearPlanDesign();
        done();
        this.toggleDialogPlanSummary({ toggle: false, product: null });
      },
    },
  };
</script>

<style lang="scss" scoped>
  :deep(.el-dialog__body) {
    color: inherit;
    padding-bottom: 0;
  }

  .logo {
    display: block;
    height: 60px;
    margin: 0 auto 20px;
  }

  hr {
    margin: 40px 0;
    border-top: 1px solid $tf-extra-light-gray-1;
  }

  h3 {
    font-size: 18px;
    margin-bottom: 30px;
  }
</style>
