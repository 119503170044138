/**
 * Product label formatting
 *
 * @param {object} product
 * @param {string} product.project_product
 * @param {string} product.product_type_name
 * @returns {string}
 */
export const productLabel = ({ project_product: projectProduct, product_type_name: name }) => {
  const label = projectProduct.label.replace(new RegExp(`^${name} *-* *`, 'i'), '');

  return `${name}${label.length ? ` - ${label}` : ''}`;
};

/**
 * List of product types that are not supported by dive, but ARE supported by ThreeFlow Assist
 * This list supports both strings and regex; if a string is passed in, it will be compared case-insensitively
 */
export const diveUnsupportedProductTypes = [
  'stop loss',
  'critical illness',
  /paid leave/,
];

/**
 * List of product types that are not supported by dive OR ThreeFlow Assist
 */
export const tfAssistAndDiveUnsupportedProductTypes = [
  'absence management',
];
