<template>
  <footer>
    <div>
      <CarrierDatepicker
        :date-default="dateValue"
        :disabled="isDisabled"
        label="Expiration date:"
        @toggle="carrierDatepickerOpen = $event"
        @confirm="setDate($event)"
      />
      <AppButton
        :is-disabled="!dateValue || isDisabled || carrierDatepickerOpen || saving"
        :is-loading="saving"
        size="small"
        icon="fa-solid fa-lock"
        text="Firm quote"
        class="btn-submit-firm-quote"
        @click="submit"
      />
    </div>
    <p
      v-if="errorOnSubmit"
      class="text-error"
    >
      There was an error submitting your firm quote. Please try again.
      If the problem persists, please <TfCtaEmailSupport />.
    </p>
  </footer>
</template>

<script>
  import { mapActions, mapState } from 'pinia';
  import { useNotificationsStore } from '@/stores/notifications.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductStore } from '@/stores/product.js';
  import DocumentServices from '@/services/documents.js';
  import { segmentData } from '@/utils/analytics.js';
  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
  import CarrierDatepicker from '@/components/CarrierDatepicker.vue';

  /**
   * Firm Quote
   *
   * @exports Product/ProductFirmQuote
   */
  export default {
    name: 'FirmQuote',
    components: { CarrierDatepicker },
    props: {
      products: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        dateValue: '',
        errorOnSubmit: false,
        saving: false,
        carrierDatepickerOpen: false,
      };
    },
    computed: {
      ...mapState(useProjectStore, [
        'broker',
        'employerName',
        'projectId',
        'proposalDocumentId',
      ]),
      /**
       * Returns a boolean based on if there are any products not in the `declined` or `completed` state or if every product has been declined.
       *
       * @returns {boolean}
       */
      isDisabled() {
        return this.products.some(({ state }) => !['declined', 'completed'].includes(state))
          || this.products.every(({ state }) => state === 'declined');
      },
    },
    /**
     * Set date value depending on if we have any expired products.
     * Declined products don't have an expiration date so we need to look for any product with an expiration date.
     */
    created() {
      const expiredProduct = this.products.find((product) => product.firm_quote_expires_on);
      const expireDate = expiredProduct
        ? expiredProduct.firm_quote_expires_on
        : null;

      this.dateValue = expiredProduct
        ? expireDate.toString()
        : null;
    },
    methods: {
      ...mapActions(useNotificationsStore, ['setAlertQuotesTab']),
      ...mapActions(useProductStore, ['setProducts']),
      /**
       * Sets our dateValue to the confirmed date from the datepicker, and sends an analytics event
       *
       * @param {Date} confirmedDate
       */
      setDate(confirmedDate) {
        this.dateValue = confirmedDate?.toString() ?? null;

        if (confirmedDate) {
          trackSegmentEvent('Firm quote date confirmed', segmentData());
        }
      },
      /**
       * Sends a API call to patch the firm quote (quote) using and updates the store to show the success/error message
       * and sends analytics.
       */
      async submit() {
        this.saving = true;

        try {
          const { document: { products } } = await DocumentServices.patchFirmQuote({
            date: this.dateValue,
            documentId: this.products[0].document_id,
          });

          this.setProducts(products);
          this.setAlertQuotesTab({
            text: `You have successfully submitted your firm quote to ${this.broker.name}.`,
            type: 'success',
          });
          this.saving = false;

          trackSegmentEvent('Firm quote date done', segmentData());
        } catch {
          this.errorOnSubmit = true;
          this.saving = false;
        }
      },
    },
  };
</script>

<style scoped>
p {
  text-align: right;
}

footer {
  padding-top: 20px;
  border-top: 1px solid var(--tf-gray-light-medium);
}

div {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
</style>
