<template>
  <div :class="{ 'card-row' : isRow }">
    <div
      class="card-title"
      data-test="card title"
    >
      <div
        class="card-title-header"
        data-test="title header"
      >
        <div>
          <h3>{{ product.project_product.label }}</h3>
          <template
            v-if="showIncumbentLabelAndPolicyIds"
          >
            <div
              class="card-label"
              :class="{ 'hovering-card-label': !isRow }"
              data-test="incumbent label"
            >
              <h4>
                Incumbent
              </h4>
            </div>
            <p
              v-if="formattedPolicyIds"
              data-test="policy ids"
            >
              {{ formattedPolicyIds }}
            </p>
          </template>
        </div>
        <!-- Button: Plan Summary (only for full width cards) -->
        <AppButton
          v-if="isRow && !isRenewalShortcut"
          :data-test="`open ${humanizedProductType} plan summary`"
          size="text"
          text="Plan summary"
          @click="togglePlanSummary"
        />
      </div>
      <ProductStateInfo
        v-bind="{ stateInfo, isRow }"
        :data-test="`${product.project_product.label} state info`"
      />
    </div>
    <!-- Add non-breaking space html entity to keep height distribution happy -->
    <template v-if="!isRow">
      <h4
        data-test="alternative product name or new coverage label"
      >
        <template v-if="product.project_product.alternative">
          {{ product.project_product.label }}
        </template>
        <template v-else-if="!product.project_product.prior_coverage">
          New coverage
        </template>
        <template v-else>
          &nbsp;
        </template>
      </h4>
      <hr>
    </template>
    <div class="card-actions">
      <!-- Decline/Review/Plan Summary buttons -->
      <div class="btn-group">
        <!-- Button: Plan Summary -->
        <AppButton
          v-if="!isRow && !isRenewalShortcut"
          :data-test="`open ${humanizedProductType} plan summary`"
          size="text"
          text="Plan summary"
          @click="togglePlanSummary"
        />
        <!-- Button: Start/Continue/Edit/Undo -->
        <AppButton
          v-if="(!isRenewalShortcut || product.state === 'declined')"
          :data-test="`${stateInfo.btnText.toLowerCase()} rfp`"
          :is-disabled="btnUndoing"
          :is-loading="btnUndoing"
          :text="stateInfo.btnText"
          :type="stateInfo.btnClass"
          size="small"
          @click="onBtnClick"
        />
      </div>
      <!-- Button: Decline/Review -->
      <AppButton
        v-if="(isComplete && !isRenewalShortcut) || showDecline"
        :data-test="`decline ${humanizedProductType} quote`"
        :text="isComplete ? 'Review' : 'Decline'"
        :type="isComplete ? 'primary' : 'danger'"
        size="text"
        @click="onTextClick"
      />
    </div>

    <DeclineToQuoteModal
      v-if="showDialogDeclineProduct"
      :products-to-decline="[product]"
      :data-test="`decline to quote ${humanizedProductType} confirmation`"
      :visible.sync="showDialogDeclineProduct"
    />
  </div>
</template>

<script>
  import { mapState, mapActions } from 'pinia';
  import { ReusableData } from '@watchtowerbenefits/shared-components';
  import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
  import { useNotificationsStore } from '@/stores/notifications.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductStore } from '@/stores/product.js';
  import DeclineToQuoteModal from '@/components/Modals/DeclineToQuoteModal.vue';
  import ProductStateInfo from '@/components/Product/ProductStateInfo.vue';
  import { carrierUiRenewalShortcutSupport } from '@/utils/featureFlags.js';
  import { segmentData } from '@/utils/analytics.js';
  import { isStopLossProduct, trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';

  /**
   * Product Status Card
   *
   * @exports Product/ProductCardInfo
   */
  export default {
    name: 'ProductCardInfo',
    components: {
      DeclineToQuoteModal,
      ProductStateInfo,
    },
    props: {
      product: {
        type: Object,
        required: true,
      },
      isRow: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        btnUndoing: false,
        showDialogDeclineProduct: false,
      };
    },
    computed: {
      ...mapState(useCarrierInfoStore, { carrierId: 'id', carrierName: 'name' }),
      ...mapState(useProjectStore, [
        'project',
        'projectId',
        'isRenewalProject',
        'proposalDocument',
        'autoRenewActivated',
      ]),
      /**
       * Evaluate carrierUiRenewalShortcutSupport feature flag and return true if it is enabled.
       *
       * @deprecated Rff:carrierUiRenewalShortcutSupport.
       * @returns {Array}
       */
      carrierUiRenewalShortcutSupportFlagEnabled() {
        return this.$ld.checkFlags(carrierUiRenewalShortcutSupport);
      },
      /**
       * Builds a string of Policy Ids to display on the product card.
       *
       * @returns {string}
       */
      formattedPolicyIds() {
        const policyIds = this.product.project_product.inforce_product.policy_ids;
        const policyIdsString = policyIds.join(', ');
        let label = 'In force policy ID';

        if (policyIds.length > 1) {
          label += 's';
        }

        return policyIds.length
          ? `${label}: ${policyIdsString}`
          : '';
      },
      /**
       * Lowercase and replace _ with spaces on the product type name.
       *
       * @returns {string}
       */
      humanizedProductType() {
        return this.product.product_type_name
          .toLowerCase()
          .split('_')
          .join(' ');
      },
      /**
       * Check to see if a product is in the completed state.
       *
       * @returns {boolean}
       */
      isComplete() {
        return this.product.state === 'completed';
      },
      /**
       * Determines whether renewal shortcuts are enabled for this project.
       *
       * @returns {boolean}
       */
      isRenewalShortcut() {
        return this.carrierUiRenewalShortcutSupportFlagEnabled
          && this.project.allow_null_products;
      },
      /**
       * check if project and product is renewal and ff is on
       *
       * @returns {boolean}
       */
      isUploadRenewal() {
        const isBaseProduct = !this.product.project_product.alternative;

        return this.autoRenewActivated && isBaseProduct;
      },
      /**
       * If a document has previously been submitted, do not show the `decline` cta
       *
       * @returns {boolean}
       */
      showDecline() {
        return !['declined', 'editing', 'completed'].includes(this.product.state) && this.proposalDocument.state !== 'finalized';
      },
      /**
       * If product is sold
       *
       * @returns {boolean}
       */
      isSold() {
        const {
          project_product: {
            sold_status: soldStatus,
          },
        } = this.product;
        const soldStatuses = ['new policy sold', 'renewed'];

        return soldStatuses.includes(soldStatus.toLowerCase());
      },
      /**
       * If product is sold to this carrier
       *
       * @returns {boolean}
       */
      soldToThisCarrier() {
        return this.carrierId === this.product.project_product.sold_carrier_id;
      },
      /**
       * If product is not sold to this carrier or not sold to any carrier
       *
       * @returns {boolean}
       */
      notSoldToThisCarrier() {
        const {
          project_product: {
            sold_status: soldStatus,
          },
        } = this.product;

        return (!this.soldToThisCarrier && this.isSold) || soldStatus.toLowerCase() === 'no policy sold';
      },
      /**
       * Determine whether we should show the incumbent label and policy ids based on whether the carrier is the incumbent on a product
       * and the product is not an alternative.
       *
       * @returns {boolean}
       */
      showIncumbentLabelAndPolicyIds() {
        const isIncumbent = this.product.project_product.inforce_carrier_id
          === this.product.carrier_id;

        return isIncumbent && !this.product.project_product.alternative;
      },
      /**
       * Object containing the icon info (icon, text, and class) and btn info (icon and class)
       *
       * @returns {object}
       */
      stateInfo() {
        let btnClass = 'secondary';
        let btnText;
        let icon;
        let iconClass;
        let iconType;
        let iconText;
        let iconSize;
        let iconSizeRow;
        let stopLossState;

        switch (this.product.state) {
        case 'completed':
          stopLossState = this.product.stop_loss_state;
          btnText = 'Edit';
          icon = 'fa-solid fa-check';
          iconType = 'success';
          iconText = 'submitted';
          iconSize = 'xl';
          iconSizeRow = '1x';

          // Covers the 3 states where the stop loss product is not in the 'no_action' state.
          if (isStopLossProduct(this.product) && stopLossState !== 'no_action') {
            // Firm Quote (quote) has been requested.
            if (stopLossState === 'firm_quote_requested') {
              icon = 'fa-regular fa-clock-five';
              iconType = 'warning';
              iconClass = 'requested';
              iconText = 'Firm requested';
              iconSize = 'lg';
              iconSizeRow = '1x';
            }
            // Firm Quote (quote) has been submitted.
            if (stopLossState === 'firm_quote_submitted') {
              icon = 'fa-solid fa-lock';
              iconText = 'Firm';
              iconSize = 'lg';
              iconSizeRow = '1x';
            }
            // Firm Quote (quote) Date has been expired.
            if (stopLossState === 'firm_quote_expired') {
              icon = 'fa-solid fa-exclamation';
              iconType = 'danger';
              iconText = 'Firm expired';
              iconSize = 'lg';
              iconSizeRow = '1x';
            }
          }
          if (this.soldToThisCarrier) {
            icon = 'fa-regular fa-medal';
            btnText = 'View';
            iconClass = 'selected';
            iconType = 'info';
            iconText = 'selected';
            iconSize = '2xl';
            iconSizeRow = 'xl';
          }
          if (this.notSoldToThisCarrier || this.product.alternative_was_sold) {
            icon = 'fa-regular fa-lock';
            btnText = 'View';
            iconType = '';
            iconText = 'Closed';
            iconSize = 'lg';
            iconSizeRow = '1x';
          }
          break;
        case 'not_started':
          btnClass = 'primary';
          btnText = 'Start';
          icon = 'fa-solid fa-ellipsis';
          iconText = 'not started';
          iconSize = 'xl';
          iconSizeRow = '1x';

          if (this.notSoldToThisCarrier) {
            icon = 'fa-regular fa-lock';
            btnText = 'View';
            iconType = '';
            iconText = 'Closed';
            iconSize = 'lg';
            iconSizeRow = '1x';
          }
          break;
        case 'declined':
          btnText = 'Undo';
          icon = 'fa-solid fa-xmark';
          iconType = 'danger';
          iconText = 'declined';
          iconSize = '2xl';
          iconSizeRow = '1x';
          break;
        default:
          btnClass = 'primary';
          btnText = 'Continue';
          icon = 'fa-solid fa-pencil';
          iconType = 'warning';
          iconText = this.isUploadRenewal ? 'in progress' : 'not submitted';
          iconSize = 'xl';
          iconSizeRow = 'sm';

          if (this.notSoldToThisCarrier) {
            icon = 'fa-regular fa-lock';
            btnText = 'View';
            iconType = '';
            iconText = 'Closed';
            iconSize = 'lg';
            iconSizeRow = '1x';
          }
        }

        return {
          btnClass,
          btnText,
          icon,
          iconClass,
          iconSize,
          iconText,
          iconType,
          iconSizeRow,
        };
      },
    },
    methods: {
      ...mapActions(useNotificationsStore, ['clearAlertsQuotesTab']),
      ...mapActions(useProductStore, [
        'toggleDialogPlanSummary',
        'updateProductState',
      ]),
      /**
       * Click method on product buttons (Continue, Edit, Start, & Undo)
       */
      async onBtnClick() {
        const productId = this.product.id.toString(); // productID param should always be a string

        if (this.product.state === 'completed') {
          trackSegmentEvent('Edit submitted quote', segmentData());
        }

        if (this.product.state === 'not_started') {
          const projectType = this.project.type === ReusableData.projectTypes.renewal
            ? 'renewal'
            : 'new market';

          trackSegmentEvent(`Start ${projectType} quote`, segmentData({
            product_type: this.product.product_type_name,
            carrier_name: this.carrierName,
            carrier_id: this.carrierId,
          }));
        }

        if (this.product.state === 'declined') {
          // Disable the button until product has been declined.
          this.btnUndoing = true;
          // hide any alerts that were open
          this.clearAlertsQuotesTab();
          try {
            await this.updateProductState({
              productId,
              endpointState: 'undecline',
            });
          } catch {
            this.$message({
              showClose: true,
              message: 'There was an error undeclining this product. Please try again.',
              type: 'error',
            });
          } finally {
            this.btnUndoing = false;
          }

          return;
        }

        this.$router.push({
          name: 'PlanDesign',
          params: {
            productId,
          },
        });
      },
      /**
       * Click method on product text (Review & Decline)
       * There are only 2 options:
       * Review: If product has been submitted
       * Decline: If product is any state other than submitted
       * If a product has already been declined, no text will show.
       */
      onTextClick() {
        if (this.isComplete) {
          this.$router.push({
            name: 'PlanDesignReview',
            params: {
              productId: this.product.id.toString(), // productID param should always be a string
            },
          });

          return;
        }

        // hide any alerts that were open
        this.clearAlertsQuotesTab();

        this.toggleDeclineProduct();
      },
      /**
       * Toggles the showDialogPlanSummary property.
       */
      togglePlanSummary() {
        this.toggleDialogPlanSummary({ toggle: true, product: this.product });
      },
      /**
       * Toggles the showDialogDeclineProduct property.
       */
      toggleDeclineProduct() {
        this.showDialogDeclineProduct = !this.showDialogDeclineProduct;
      },
    },
  };
</script>

<style lang="scss" scoped>
  hr {
    margin: 24px 0 27px;
    border-color: var(--tf-gray-medium);
  }

  .card-title-header {
    flex: 1;
    margin-bottom: 0;
    padding-right: 10px;

    h3 {
      font-size: 20px;
      font-weight: 700;
      display: inline;
      margin-right: 20px;

      .card-row & {
        margin-bottom: 0;
      }
    }

    p {
      color: var(--tf-gray);
      white-space: wrap;
    }

    .card-row & {
      display: flex;
      flex-direction: column;
      flex: inherit;
      width: 550px;
    }
  }

  h4 {
    margin: 0;
    overflow: hidden;
    font-size: 14px;
    font-weight: 400;
    color: var(--tf-blue-dark);
    text-overflow: ellipsis;
    white-space: nowrap;

    .card-row & {
      display: flex;
    }
  }

  .card-title,
  .card-actions {
    display: flex;
  }

  .card-actions {
    justify-content: space-between;
    flex-direction: row-reverse; /* Needs to be row reverse in case text-link isn't present. */
    gap: var(--tf-btn-margin-medium);
  }

  .card-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid var(--tf-gray-light-medium);
    align-items: baseline;

    &:last-child {
      border-bottom: none;
    }

    /* stylelint-disable-next-line selector-max-compound-selectors */
    .btn-group .tf-button + .tf-button {
      /* stylelint-disable-next-line declaration-no-important */
      margin-left: 0 !important;
    }
  }

  .card-title {
    .card-row & {
      justify-content: space-between;
      min-width: 400px;
      align-items: baseline;
    }

    .card-label {
      display: inline-block;
      background: var(--tf-green);
      text-transform: uppercase;
      letter-spacing: 1px;
      padding: 7px 25px;

      h4 {
        color: var(--tf-white);
        font-weight: 700;
      }

      &.hovering-card-label {
        position: absolute;
        top: -15px;
        left: -8px;
        margin: auto;
      }
    }
  }

  .tf-button--text {
    .card-row & {
      margin-right: 20px;
      padding: 7px 0;
    }
  }
</style>
