<template>
  <section v-loading="!products.length">
    <div
      v-if="products.length"
      id="quotes-content-container"
    >
      <header>
        <div class="alert-container">
          <ElAlert
            v-if="alertQuotesTab.type"
            data-test="your quotes alert message"
            :closable="alertQuotesTab.closable"
            :type="alertQuotesTab.type"
            show-icon
            :title="alertQuotesTab.text"
          />
        </div>

        <div class="project-actions-container">
          <div class="btn-group">
            <AppButton
              v-if="canRatePassRenew && !isRenewalShortcut"
              :is-disabled="isRatePassDisabled"
              data-test="rate pass renew"
              icon="fa-solid fa-rotate"
              @click="openRenewalModal"
            >
              Offer rate pass
            </AppButton>
            <AppButton
              v-if="canUploadRenewal"
              data-test="auto renew all"
              icon="fa-solid fa-file-upload"
              @click="isProposalManagerModalOpen = true"
            >
              Upload renewal
            </AppButton>
          </div>
          <AppButton
            id="decline-all-button"
            :is-disabled="!canDeclineAllProducts"
            data-test="decline all products"
            type="secondary"
            @click="onDeclineAllProductsClick"
          >
            Decline all
          </AppButton>
        </div>
      </header>
      <div
        v-if="products.length"
        class="product-container deprecated"
      >
        <ProductCard
          v-for="product in productsTree"
          :key="product.label"
          :data-test="`${product.label.toLowerCase()} product card`"
          :product-card-info="product"
          :single-product="productsTree.length === 1 && !product.alternatives.length"
        />
      </div>
    </div>
    <DeclineToQuoteModal
      v-if="showDeclineAllDialog"
      :visible.sync="showDeclineAllDialog"
      data-test="decline all products confirmation"
    />
    <PlanSummaryModal
      v-if="showDialogPlanSummary"
      :product="currentProduct"
      data-test="your quote plan summary"
      visible
    />
    <ProposalManagerModal
      v-if="proposalDocument && canUploadRenewal"
      :visible.sync="isProposalManagerModalOpen"
      :is-auto-renewal="true"
      :proposals="proposals"
    />
    <RatePassRenewByProductModal
      v-if="notStartedRenewalProducts.length"
      :products="notStartedRenewalProducts"
      :document-id="proposalDocument.id"
      :visible.sync="isRatePassRenewByProductModalOpen"
      data-test="rate pass renew by product modal"
      @closeDialog="closeRatePassRenewByProductModal"
    />
  </section>
</template>

<script>
  // State
  import { mapActions, mapState } from 'pinia';
  import { useUserPreferredPdvsStore } from '@/stores/userPreferredPlanDesignValues.js';
  import { useNotificationsStore } from '@/stores/notifications.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductStore } from '@/stores/product.js';
  // Feature Flag
  import {
    saveUserDefaultPlanDesignValues,
    carrierUiRenewalShortcutSupport,
  } from '@/utils/featureFlags.js';
  // Components
  import DeclineToQuoteModal from '@/components/Modals/DeclineToQuoteModal.vue';
  import PlanSummaryModal from '@/components/Modals/PlanSummaryModal.vue';
  import ProposalManagerModal from '@/components/Modals/ProposalManagerModal.vue';
  import RatePassRenewByProductModal from '@/components/Modals/StartOrUpdateRenewalModal/RatePassRenewByProductModal.vue';
  // Utils
  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
  import ProductCard from '@/components/Product/ProductCard.vue';

  /**
   * Project Overview Quotes Tab. Displays the old Product Cards and will be deprecated and replaced by the new YourQuotesWithProductTable component.
   * rff:uploadRenewalUploadEnhancements - Upon removal of this feature flag, this component and it's test will no longer be needed.
   * Additionally, ProposalManagerModal file can also be removed when the uploadRenewalUploadEnhancements flag is removed.
   */
  export default {
    name: 'YourQuotesWithProductCards',
    components: {
      DeclineToQuoteModal,
      PlanSummaryModal,
      ProductCard,
      ProposalManagerModal,
      RatePassRenewByProductModal,
    },
    data: () => ({
      showDeclineAllDialog: false,
      isProposalManagerModalOpen: false,
      isRatePassRenewByProductModalOpen: false,
    }),
    computed: {
      ...mapState(useUserPreferredPdvsStore, ['productsWithPreferredPdvs']),
      ...mapState(useNotificationsStore, ['alertQuotesTab']),
      ...mapState(useProjectStore, [
        'proposalDocument',
        'proposals',
        'project',
      ]),
      ...mapState(useProductStore, [
        'showDialogPlanSummary',
        'currentProduct',
        'products',
      ]),
      /**
       * Organizes the products for the product cards.
       *
       * @returns {Array}
       */
      productsTree() {
        const productTypes = [];
        const createProduct = ({ alternative = false, product }) => {
          let progressIcon;

          if (product.state === 'completed') {
            progressIcon = 'full';
          } else if (!['not_started', 'declined'].includes(product.state)) {
            progressIcon = 'half';
          }

          return {
            alternative,
            alternatives: [],
            label: alternative
              ? product.project_product.label
              : product.project_product.product_type_name,
            projectProductId: product.project_product.id,
            progressIcon,
            productId: product.id,
            productTypeId: product.project_product.product_type_id,
            state: product.state,
          };
        };

        // Push all base products already sorted by positioning.
        this.products
          .filter((product) => !product.project_product.alternative)
          .forEach((product) => {
            productTypes.push(createProduct({ product }));
          });

        // Push all alternative products to the base product object.
        this.products
          .filter((product) => product.project_product.alternative)
          .forEach((product) => {
            const baseProduct = productTypes.find(
              ({ productTypeId }) => productTypeId === product.project_product.product_type_id,
            );

            if (baseProduct) {
              baseProduct.alternatives.push(createProduct({
                alternative: true,
                product,
              }));
            }
          });

        const stopLossProducts = productTypes.filter((productType) => productType.label.toLowerCase() === 'stop loss');
        const nonMedProducts = productTypes
          .filter((productType) => productType.label.toLowerCase() !== 'stop loss')
          .sort((a, b) => {
            const lengthA = a.alternatives.length;
            const lengthB = b.alternatives.length;
            let sortOrder = 0;

            if (lengthA < lengthB) {
              sortOrder = 1;
            }

            if (lengthA > lengthB) {
              sortOrder = -1;
            }

            return sortOrder;
          });

        return [
          ...stopLossProducts,
          ...nonMedProducts,
        ];
      },
      /**
       * Evaluates the saveUserDefaultPlanDesignValues feature flag.
       *
       * @returns {boolean}
       */
      ffSaveUserDefaultPlanDesignValues() {
        return this.$ld.checkFlags(saveUserDefaultPlanDesignValues);
      },
      /**
       * Evaluate carrierUiRenewalShortcutSupport feature flag and return true if it is enabled.
       *
       * @deprecated Rff:carrierUiRenewalShortcutSupport.
       * @returns {Array}
       */
      carrierUiRenewalShortcutSupportFlagEnabled() {
        return this.$ld.checkFlags(carrierUiRenewalShortcutSupport);
      },

      /**
       * Users should only be able to firm quote if all stop loss products are finished
       * and at least one is not declined.
       *
       * @returns {boolean}
       */
      canFirmQuote() {
        const stopLossProducts = this.products.filter((product) => product.product_type_name.toLowerCase() === 'stop loss');
        const isAllFinished = stopLossProducts.every((product) => ['declined', 'completed'].includes(product.state));
        const notAllDeclined = !stopLossProducts.every((product) => product.state === 'declined');

        return isAllFinished && notAllDeclined;
      },
      /**
       * Determines if some products are incumbent products and no alternatives.
       *
       * @returns {boolean}
       */
      isIncumbentAndNoAlternativesOnSome() {
        return this.products
          .some((product) => product.project_product.inforce_carrier_id === product.carrier_id
            && !product.project_product.alternative);
      },
      /**
       * Determines if some products are renewals.
       *
       * @returns {boolean}
       */
      isSomeRenewalsNotStarted() {
        return this.products.some((product) => this.productIsRenewalAndNotStarted(product));
      },
      /**
       * Determines if all products are incumbent products and no alternatives.
       *
       * @returns {boolean}
       */
      isIncumbentAndNoAlternativesOnAll() {
        return this.products
          .every((product) => product.project_product.inforce_carrier_id === product.carrier_id
            && !product.project_product.alternative);
      },
      /**
       * Determines whether renewal shortcuts are enabled for this project.
       *
       * @returns {boolean}
       */
      isRenewalShortcut() {
        return this.carrierUiRenewalShortcutSupportFlagEnabled
          && this.project.allow_null_products;
      },
      /**
       * Determines if project is eligible for auto-renew feature. Requires at least one product to be a renewal.
       *
       * @returns {boolean}
       */
      canUploadRenewal() {
        // Filter out alternatives because they are technically 'proposal' document_types but they are eligible for auto-renew.
        const baseProducts = this.products
          .filter((product) => !product.project_product.alternative);

        return baseProducts.some((product) => product.document_type.toLowerCase() === 'renewal');
      },
      /**
       * Determines the button type to display for the auto-renew button. If the rate pass renew feature is
       * available for this project, then the auto-renew button should be secondary to the rate pass renew button.
       *
       * @returns {string}
       */
      autoRenewButtonType() {
        return this.ffRatePassRenew ? 'secondary' : 'primary';
      },
      /**
       * Determines if a project is eligible for Rate Pass Renewal. No products can be started,
       * and some product document_types must be 'renewal'. (alternatives are proposal document_types),
       *
       * @returns {boolean}
       */
      canRatePassRenew() {
        return this.isSomeRenewalsNotStarted;
      },
      /**
       * Determines if the Rate Pass Renew button should be disabled. If no products are Not Started, or if documents are processing then
       * we should not allow the ability to rate pass renew.
       *
       * @returns {boolean}
       */
      isRatePassDisabled() {
        return !this.someProductsNotStarted;
      },
      /**
       * Determines if the every product is in a not_started state.
       *
       * @returns {boolean}
       */
      allProductsNotStarted() {
        return this.products.every((product) => product.state.toLowerCase() === 'not_started');
      },
      /**
       * Determines if some products are in a not_started state.
       *
       * @returns {boolean}
       */
      someProductsNotStarted() {
        return this.products.some((product) => product.state.toLowerCase() === 'not_started');
      },
      /**
       * Filters all products down to only renewal products that have not been started.
       *
       * @returns {boolean}
       */
      notStartedRenewalProducts() {
        return this.products.filter((product) => (
          this.productIsRenewalAndNotStarted(product)
        ));
      },
      /**
       * If any product has been submitted or declined, if the project has been completed or if documents are processing,
       * then the decline all button should be disabled.
       *
       * @returns {boolean}
       */
      canDeclineAllProducts() {
        return this.proposalDocument.state !== 'finalized'
          && this.products.every((product) => !['completed', 'declined'].includes(product.state));
      },
    },
    mounted() {
      if (this.ffSaveUserDefaultPlanDesignValues && this.someProductsNotStarted) {
        this.getProductsWithPreferredPdvs();
      }
      if (this.isRenewalShortcut) {
        this.setAlert({
          text: 'Upload your renewal documents for the products you wish to quote, and we will deliver them directly to the broker.',
          type: 'info',
          visible: true,
          closable: true,
        });
      }

      // If you come to the page, all products are not started, and you are incumbent with no alternatives and
      // some products are renewals, modal opens automatically.
      if (this.allProductsNotStarted && !this.isRenewalShortcut) {
        const renewalConditions = this.isIncumbentAndNoAlternativesOnAll
          && this.isSomeRenewalsNotStarted
          && this.project.type === 'Projects::RenewalPlacementEvent';
        const gtmConditions = this.project.type === 'Projects::RfpPlacementEvent'
          && this.isIncumbentAndNoAlternativesOnSome;

        if (renewalConditions || gtmConditions) {
          this.isRatePassRenewByProductModalOpen = true;
          this.ratePassModalDisplayedAnalytics();
        }
      }
    },
    /**
     * Clear any any alerts or modals that were open when destroying this view.
     */
    beforeDestroy() {
      this.clearAlertsQuotesTab();
      this.toggleDialogPlanSummary({ toggle: false, product: null });
    },
    methods: {
      ...mapActions(useProductStore, ['toggleDialogPlanSummary']),
      ...mapActions(useUserPreferredPdvsStore, ['getProductsWithPreferredPdvs']),
      ...mapActions(useNotificationsStore, ['clearAlertsQuotesTab', 'setAlert']),
      /**
       * Closes the rate pass renew by product modal and sends an analytics call.
       *
       * @param {number} stepNumber - which step the user was on before they closed the modal
       * @param {Array} selectedProducts - which products the user selected before they closed the modal
       */
      closeRatePassRenewByProductModal(stepNumber, selectedProducts) {
        this.isRatePassRenewByProductModalOpen = !this.isRatePassRenewByProductModalOpen;
        trackSegmentEvent(`Rate pass modal abandoned step ${stepNumber}`, {
          product_ids: selectedProducts,
        });
      },
      /**
       * Helper to check for products that are both not_started state and have a renewal document_type.
       *
       * @param {object} product
       * @returns {boolean}
       */
      productIsRenewalAndNotStarted(product) {
        return product.state.toLowerCase() === 'not_started' && product.document_type.toLowerCase() === 'renewal';
      },
      /**
       * Opens the renewal modal and sends an analytics event
       */
      openRenewalModal() {
        this.isRatePassRenewByProductModalOpen = true;
        this.ratePassModalDisplayedAnalytics();
      },
      /**
       * Handle click on 'Decline All Products' button
       */
      onDeclineAllProductsClick() {
        this.showDeclineAllDialog = !this.showDeclineAllDialog;
      },
      /**
       * Send Analytics event when rate pass modal is displayed
       */
      ratePassModalDisplayedAnalytics() {
        trackSegmentEvent('Rate pass modal displayed', {
          product_ids: [...this.notStartedRenewalProducts.map(({ id }) => id)],
        });
      },
      /**
       * Get product data into format for product card
       * This is mostly a pattern match for the old product card to limit the refactor
       * The ProductCard component can probably be refactored to use the product state in the future
       *
       * It does add a name for product alts to add the product type name before the alternative label
       * It also uses the stopLossState for the product state if the product is a stop loss and the product state is completed
       *
       * @param {object} product
       * @param {number} product.id
       * @param {string} product.state
       * @param {number} product.project_product
       * @param {string} product.stop_loss_state
       * @returns {object}
       */
      getProductCardInfo({
        id,
        state,
        project_product: projectProduct,
        stop_loss_state: stopLossState,
      }) {
        const {
          label,
          alternative,
          product_type_name: productTypeName,
          product_type_id: productTypeId,
        } = projectProduct;
        // prefix label with product type if it is an alternative
        const formattedLabel = projectProduct.alternative
          ? `${productTypeName} - ${label}`
          : productTypeName;
        // use stopLossState for state if product is Stop Loss and product state is completed
        // stopLostState is not relevant until a broker asks for a firm quote on a completed product.
        const formattedState = productTypeName.toLowerCase() === 'stop loss' && state === 'completed'
          ? stopLossState
          : state;
        const productInfo = {
          alternative,
          productTypeName,
          productTypeId,
          productId: id,
          label: formattedLabel,
          projectProductId: projectProduct.id,
          state: formattedState,
        };

        return productInfo;
      },
    },
  };
</script>

<style lang="scss" scoped>
  #quotes-content-container {
    margin: 0 auto;
    min-height: calc(100vh - 385px); /* pushes footer to the bottom when there are few products */
    max-width: $max-width;
  }

  header {
    padding: 0 20px;
  }

  :deep(.cp-accordion) {
    margin: 2rem 0;

    summary {
      padding: .4rem;
    }
  }

  :deep(.cp-accordion__content) {
    padding: .5rem;
  }

  .alert-container {
    display: flex;
    width: 100%;
    justify-content: center;

    .el-alert {
      margin-bottom: 20px;
    }
  }

  .stop-loss-header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    h4 {
      margin: 0;
      font-size: 16px;
    }

    button {
      margin-left: 1rem;
    }

    .icon-dollar {
      margin-left: .5rem;
    }
  }

  .project-actions-container {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .button-text > svg {
      margin-left: 6px;
    }

    /*
      These next two rules will keep the decline all button to the
      right of the row even if the upload button is not present
    */

    #decline-all-button {
      margin: 0 0 0 auto;
    }
  }

  .product-container {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    gap: 10px;

    &.deprecated {
      gap: 0;
    }
  }
</style>
