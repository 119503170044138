<template>
  <td
    :class="{
      'cell-empty' : !cellData.label,
      'cell-show-input' : !readonly && cellData.label }"
    width="25%"
  >
    <ElFormItem
      v-if="!readonly && cellData.label"
      ref="formItem"
      size="medium"
      label-width="0"
      :prop="formProp"
      :show-message="showErrorMessage"
      :error="errorMessage"
      :class="{
        'hide-error': hideErrorState,
        'hide-error-message': !showErrorMessage
      }"
    >
      <ElInput
        v-model="localValue"
        placeholder="Enter rate"
        data-test="rate field"
        :class="[!value ? 'input-blank' : '', {
          'hide-error': hideErrorState,
          'updating': updatePending,
        }]"
        @blur="localValueChanged"
      />
    </ElFormItem>
    <!-- Only show values if there is an associated label ) -->
    <span
      v-else-if="cellData.label"
      data-test="read only rate value"
    >
      {{ cellData.value && !isNaN(cellData.value)
        ? parseFloat(cellData.value).toFixed(3)
        : '&mdash;'
      }}
    </span>
  </td>
</template>

<script>
  import { useRateEntryStore } from '@/stores/rateEntry.js';
  import { useProductStore } from '@/stores/product.js';
  import { mapActions, mapState, mapWritableState } from 'pinia';

  /**
   * Rate Entry Table Cell Value
   *
   * @exports RateEntry/RateEntryTdValue
   */
  export default {
    name: 'RateEntryTdValue',
    inject: [
      'storeAttributeId',
    ],
    props: {
      /**
       * Cell Data used for cell value information
       */
      cellData: {
        type: Object,
        required: true,
        default: () => {},
      },
      /**
       * Readonly boolean used to determine if the proposal table should be inputs.
       */
      readonly: {
        type: Boolean,
        required: true,
        default: false,
      },
      /**
       * When there are tier groups, subtypeId is used to target the specific value array in the rateAttributes store
       */
      subtypeId: {
        type: [Number, String],
        default: 0,
      },
      /**
       * Form key for validation
       */
      formKey: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        localValue: '',
        errorMessage: null,
        hasUnsavedChanges: false,
        isInitValue: true,
        initValue: null,
        updatePending: false,
      };
    },
    computed: {
      ...mapWritableState(useRateEntryStore, ['editingRateAttributeValue', 'rateUpdateRequests', 'rateErrors']),
      ...mapState(useRateEntryStore, ['rateAttributes', 'drainingUpdateRequests']),
      ...mapState(useProductStore, ['productId', 'productState', 'isProductNotStartedOrNotSubmitted']),
      /**
       * Form prop for validation
       *
       * @returns {string}
       */
      formProp() {
        return this.formKey || this.cellData.label;
      },
      /**
       * Hide warning border on not started / not subnmitted products
       *
       * @returns {boolean}
       */
      hideErrorState() {
        return (!this.showErrorMessage && this.isProductNotStartedOrNotSubmitted)
          || (this.showErrorMessage && this.value);
      },
      /**
       * Array of rate value to search through to find cell value
       *
       * @returns {Array}
       */
      rateValues() {
        const rateAttribute = this.rateAttributes[this.storeAttributeId];

        return rateAttribute ? rateAttribute.rateValues[this.subtypeId] : null;
      },
      /**
       * Value string of the cell to use in valueModel
       *
       * @returns {string}
       */
      value() {
        return this.rateValues
          ? this.rateValues.values.find((value) => value.label === this.cellData.label)?.value ?? ''
          : '';
      },
      /**
       * Show the error message only if the user had deleted a value
       *
       * @returns {boolean}
       */
      showErrorMessage() {
        return this.errorMessage
          || (this.hasUnsavedChanges && !this.drainingUpdateRequests && this.isInitValue)
          || (!this.isInitValue && !this.localValue)
          || (!this.value && this.value !== this.initValue && this.isInitValue)
          || (!this.rateValues?.values
            .find((value) => value.label === this.cellData.label) && this.isInitValue && !this.hasUnsavedChanges);
      },
    },
    watch: {
      value: {
        handler(value) {
          this.localValue = value !== '' && !Number.isNaN(Number(value))
            ? parseFloat(value).toFixed(3)
            : '';
        },
        immediate: true,
      },
    },
    created() {
      this.initValue = this.value;
    },
    methods: {
      ...mapActions(useRateEntryStore, [
        'drainUpdateRequests',
        'getRateEntry',
        'updateRateAttribute',
        'constructRateAttributes',
        'setClassTypeContainers',
        'setPlanTypeContainers',
      ]),
      /**
       * Removes all non-digits and special characters outside `.` and sets format to `X.XXX`.
       */
      localValueChanged() {
        let value = this.localValue.toString().replace(/[^0-9.]/g, '');

        this.hasUnsavedChanges = true;

        if (value && Number.isNaN(Number(value))) {
          this.errorMessage = 'Rates must be numerical.';

          return;
        }

        value = value && !Number.isNaN(Number(value))
          ? parseFloat(value).toFixed(3)
          : '';

        this.localValue = value;
        this.errorMessage = null;

        if (this.value !== value) {
          this.updateValue({
            value,
          });
        }
      },
      /**
       * Updates the store rateEntryValues[subtypeId][label].value
       *
       * @param {object} root0
       * @param {string} root0.value
       */
      async updateValue({ value }) {
        this.isInitValue = false;
        this.updatePending = true;
        this.$emit('updateTriggered', this.formKey, value);

        /**
         * Push an IIFE to the rateUpdateRequests queue/array
         * Use an IIFE to maintain the argument values (payload) so that
         * when the method executes it will use the correct data
         */
        this.rateUpdateRequests.push(await (async (payload) => async (rateAttributes) => {
          /**
           * The rateAttributes argument, if it exists, will be passed from the previous update
           */
          const { values, type } = rateAttributes
            ? rateAttributes.rateValues[payload.subtypeId]
            : this.rateAttributes[payload.storeAttributeId].rateValues[payload.subtypeId];
          const rateValues = {};

          /**
           * Must pass the entire tier group in each request
           */
          rateValues[payload.subtypeId] = {
            type,
            values: values.map((rateValue) => {
              if (rateValue.label === payload.label) {
                return {
                  ...rateValue,
                  value,
                };
              }

              return rateValue;
            }),
          };

          // Do the patch
          const newRateAttributes = await this.updateRateAttribute({
            ...payload,
            rateValues,
          }, rateAttributes);

          this.rateErrors.splice(0, this.rateErrors.length);
          this.hasUnsavedChanges = false;
          this.isInitValue = !!value;
          this.updatePending = false;

          this.$emit('updateTriggered', this.formKey);

          // and pass it to (potentially) the next update event IIFE
          return newRateAttributes[payload.storeAttributeId];
        })({
          productId: this.productId,
          productState: this.productState,
          storeAttributeId: this.storeAttributeId,
          subtypeId: this.subtypeId,
          label: this.cellData.label,
          skipDelete: true,
        }));

        // Kick off the rate update queue
        this.drainUpdateRequests();
      },
    },
  };
</script>

<style lang="scss" scoped>
  /* stylelint-disable selector-max-compound-selectors */
  :deep() {
    .el-form-item.is-error {
      .el-input__inner {
        border-color: var(--tf-gray-medium);
      }

      .el-input:not(.hide-error) {
        .el-input__inner {
          border-color: var(--tf-warning);
        }
      }

      &.hide-error-message {
        .el-form-item__error {
          display: none;
        }
      }
    }
  }
  /* stylelint-enable selector-max-compound-selectors */
  :deep(.el-form-item__error) {
    position: relative;
  }
</style>
