<template>
  <TfProductSelector
    v-if="selectedIds.length"
    v-model="selectedIds"
    :disabled-products="disabledProducts"
    :products="products.map(product => product.project_product)"
    :show-category-titles="false"
    :width="400"
  >
    <!-- Validation icon for each product -->
    <ProgressSlot
      slot="productOptionAppend"
      slot-scope="productOptionProps"
      :project-product-id="productOptionProps.product.id"
      :is-active="selectedIds.includes(productOptionProps.product.id)"
    />
  </TfProductSelector>
</template>

<script>
  import { mapState } from 'pinia';
  import { useProductStore } from '@/stores/product.js';
  import ProgressSlot from './ProgressSlot.vue';

  /**
   * The Project Product Product selector for all active products.
   *
   * @exports ProjectProductSelector
   */
  export default {
    name: 'ProjectProductSelector',
    components: { ProgressSlot },
    computed: {
      ...mapState(useProductStore, [
        'productId',
        'products',
        'isUploadRenewalRatePass',
        'isSmartProposal',
      ]),
      /**
       * Returns an array of project product IDs based on if the product state is `declined`.
       *
       * @returns {Array}
       */
      disabledProducts() {
        return this.products
          .filter((product) => product.state === 'declined')
          .map((product) => product.project_product.id);
      },
      selectedIds: {
        /**
         * Returns the selected Project Product ID. On change, rateEntry/planDesign is validated and route is updated.
         *
         * @returns {number}
         */
        get() {
          return this.productId && this.products.length
            ? [this.products.find((product) => product.id === this.productId).project_product.id]
            : [];
        },
        /**
         * validates plan design and rate entries
         *
         * @param {string|number} value
         */
        set(value) {
          const product = this.products.find(({ project_product: { id } }) => id === value[0]);
          let routeName;

          if (this.isUploadRenewalRatePass || this.isSmartProposal) {
            routeName = 'RateEntry';
          } else if (product.state === 'completed') {
            routeName = 'PlanDesignReview';
          } else {
            routeName = 'PlanDesign';
          }

          this.$router.push({
            name: routeName,
            params: {
              productId: product.id.toString(), // productID param should always be a string
            },
          });
        },
      },
    },
  };
</script>
