<template>
  <ElForm>
    <ElFormItem>
      <label
        class="form-label"
        v-text="label"
      />
      <ElInput
        ref="input-datepicker"
        v-model="formattedDateValue"
        :disabled="disabled"
        placeholder="Pick a date"
        @focus="onFocus"
        @keydown.native.prevent
      />
      <div
        v-if="active"
        v-click-outside="hideDatepicker"
        class="datepicker-container"
      >
        <datepicker
          v-model="dateValue"
          :disabled-dates="disabledDates"
          :inline="true"
          :open-date="calendarOpenDate"
          calendar-class="calendar_content"
          data-test="carrier datepicker"
        />
        <div class="btn-group">
          <AppButton
            :is-disabled="!dateValue"
            size="text"
            text="Reset"
            data-test="click reset button"
            type="primary"
            @click="resetDate"
          />
          <AppButton
            :is-disabled="!dateValue"
            data-test="click done button"
            text="Done"
            type="primary"
            @click="confirm"
          />
        </div>
      </div>
    </ElFormItem>
  </ElForm>
</template>

<script>
  import { mapState } from 'pinia';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductStore } from '@/stores/product.js';

  /**
   * Carrier Datepicker
   *
   * @exports CarrierDatepicker
   */
  export default {
    name: 'CarrierDatepicker',
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      dateDefault: {
        type: String,
        default: null,
      },
      datesDisabled: {
        type: Date,
        default: () => new Date(),
      },
      label: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        active: false,
        opening: false,
        calendarOpenDate: !this.dateDefault
          ? new Date()
          : null,
        dateValue: this.dateDefault,
        disabledDates: {
          to: this.datesDisabled,
        },
      };
    },
    computed: {
      ...mapState(useProjectStore, [
        'projectId',
        'employerName',
        'proposalDocumentId',
      ]),
      ...mapState(useProductStore, ['productId']),
      /**
       * Returns a `MM/DD/YY` format based on the dateValue.
       *
       * @returns {string}
       */
      formattedDateValue() {
        const newDate = new Date(this.dateValue);

        return this.dateValue
          ? `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`
          : null;
      },
    },
    methods: {
      /**
       * sends emits to confirm value and untoggle value
       */
      confirm() {
        this.$emit('confirm', this.dateValue);
        this.$emit('toggle', false);
        this.active = false;
      },
      /**
       * Closes the datepicker when clicking outside the container to reset `dateValue` to the dateDefault.
       */
      hideDatepicker() {
        if (this.opening) {
          return;
        }

        this.active = false;
        this.$emit('confirm', this.dateValue);
        this.$emit('toggle', this.active);
      },
      /**
       * Opens the datepicker component and blurs the input so the cursor is removed.
       * There is a timing issue where focusing on the input happens at the
       * the same time as when clicking "outside" the datepicker, which closes
       * the datepicker. As a workaround, we're setting opening to true and then clearing out in a timeout.
       */
      onFocus() {
        this.active = true;
        this.opening = true;
        this.$refs['input-datepicker'].blur();
        this.$emit('toggle', this.active);

        setTimeout(() => {
          this.opening = false;
        }, 250);
      },
      /**
       * Reset the datepicker selected date to the default date and fire an analytics event
       */
      resetDate() {
        this.dateValue = null;
      },
    },
  };
</script>

<style lang="scss" scoped>
.datepicker-container {
  position: absolute;
  bottom: calc(100% + 12px);
  right: 0;
  margin-top: 0; /* There is margin-top coming from the shared styles. */
  padding-bottom: 10px;
  border: 1px solid $tf-light-gray;
  background: $tf-white;

  &:after,
  &:before {
    position: absolute;
    top: 100%;
    height: 0;
    width: 0;
    border: solid transparent;
    content: '';
  }

  &:after {
    right: 50px;
    border-color: transparent;
    border-top-color: $tf-white;
    border-width: 8px;
    margin-left: -8px;
  }

  &:before {
    right: 49px;
    border-color: transparent;
    border-top-color: $tf-light-gray;
    border-width: 9px;
    margin-left: -9px;
  }

  /* Style to override shared repo styles */
  :deep(.vdp-datepicker__calendar) {
    border: none;
    box-shadow: none;
  }

  :deep(.calendar_content) {
    /* stylelint-disable-next-line declaration-no-important */
    height: inherit !important; /* needs important to override shared repo styles. */
  }
}

:deep() .el-form-item {
  margin-bottom: 0;

  &__content {
    display: inline-flex;
    align-items: center;
  }

  .el-input {
    display: inherit;
    width: 110px;
    margin-left: 15px;
  }

  /* This should really be controlled in the shared repo at some point. */
  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
}
</style>
