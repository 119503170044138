<template>
  <div
    :class="[
      { 'full-row ' : productGroup.length },
      { 'single-product' : singleProduct },
      'product-status-card'
    ]"
  >
    <template v-if="productGroup.length">
      <header>
        <div>
          <h2>{{ productCardInfo.label }}</h2>
          <p
            v-if="!productGroup[0].project_product.prior_coverage"
            v-text="'New coverage'"
          />
        </div>
      </header>
      <hr>
      <ProductCardInfo
        v-for="(product, index) in productGroup"
        :key="product.project_product.label + index"
        :product="product"
        is-row
      />
      <ProductFirmQuote
        v-if="isStopLoss"
        :products="productGroup"
      />
    </template>
    <ProductCardInfo
      v-else
      :product="products
        .find(product => product.project_product_id === productCardInfo.projectProductId)"
    />
  </div>
</template>

<script>
  import { mapState } from 'pinia';
  import ProductCardInfo from '@/components/Product/ProductCardInfo.vue';
  import ProductFirmQuote from '@/components/Product/ProductFirmQuote.vue';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductStore } from '@/stores/product.js';
  /**
   * Product Card
   *
   * @exports Product/ProductCard
   */
  export default {
    name: 'ProductCard',
    components: {
      ProductCardInfo,
      ProductFirmQuote,
    },

    props: {
      productCardInfo: {
        type: Object,
        default: () => ({}),
      },
      singleProduct: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      ...mapState(useProductStore, ['products']),
      ...mapState(useProjectStore, ['isRenewalProject', 'autoRenewActivated']),
      /**
       * Determines if the card is a Stop Loss product.
       *
       * @returns {boolean}
       */
      isStopLoss() {
        return this.productCardInfo.label.toLowerCase() === 'stop loss';
      },

      /**
       * Creates an array of products with the same product type.  If the carrier shortcut ff is on
       * we check if any of these were sold and add a prop we can check in ProductCardInfo to make
       * the status closed
       *
       * @returns {Array}
       */
      productGroup() {
        const group = this.productCardInfo.alternatives.length || this.isStopLoss
          ? this.products.filter(
            // eslint-disable-next-line max-len
            (product) => product.project_product.product_type_id === this.productCardInfo.productTypeId,
          )
          : [];

        if (group.length > 0
          && group.some((product) => product.is_sold)
        ) {
          const altGroup = group.map((product) => (product.is_sold
            ? product
            : { ...product, alternative_was_sold: true }));

          return altGroup;
        }

        return group;
      },
    },
  };
</script>

<style lang="scss" scoped>
  hr {
    margin: 24px 0 27px;
    border-color: $tf-medium-gray;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      padding-right: 20px;
    }
  }

  .product-status-card {
    width: 90%;
    margin: 10px auto;
    padding: 30px;
    background: $tf-extra-light-gray-2;
    border: 1px solid $tf-extra-light-gray-1;
    position: relative;

    @media (min-width: 850px) {
      margin: 20px;

      &.full-row {
        width: 100%;
        padding: {
          top: 20px;
          bottom: 20px;
        }
      }

      /* :not(.full-row) is to cover if there is a single Stop Loss product */
      /* That is .single-product.full-row */
      &.single-product:not(.full-row) {
        max-width: 544px;
      }

      &:not(.full-row) {
        width: calc(50% - 102px); /* (20px margin) * 2 + (30px padding) * 2 */
      }
    }
  }
</style>
