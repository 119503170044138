<script>
  /**
   * A middle-man component that checks the value of a LaunchDarkly feature flag and
   * the optionalCondition prop and renders one of two components depending
   * on the flag and optionalCondition comparison value.
   *
   * @exports LdRouteSwitch
   */
  export default {
    name: 'LdRouteSwitch',
    props: {
      trueComponent: {
        type: [Function, Object, Promise],
        required: true,
      },
      falseComponent: {
        type: [Function, Object, Promise],
        required: true,
      },
      requiredFeatureFlag: {
        type: String,
        required: true,
      },
      componentProps: {
        type: Object,
        default: () => ({}),
      },
      optionalCondition: {
        type: [Function, Boolean],
        default: true,
      },
    },
    computed: {
      /**
       * Returns value from optionalCondition prop if function or boolean.
       *
       * @returns {boolean}
       */
      optionalConditionValue() {
        const conditionIsFunction = typeof this.optionalCondition === 'function';

        return conditionIsFunction
          ? this.optionalCondition()
          : this.optionalCondition;
      },
      /**
       * Calls checkFlags and returns the flag boolean value.
       *
       * @returns {boolean}
       */
      flagValue() {
        return this.$ld.checkFlags(this.requiredFeatureFlag);
      },
      /**
       * Create an array our of the false and true components, false being 0 and true being 1 in the array.
       * It also resolves any components that might by dynamically imported as they come in as a Promise rather
       * than an object or Function.
       *
       * @returns {Array}
       */
      computedComponents() {
        return [this.falseComponent, this.trueComponent].map((component) => {
          if (!!component && typeof component.then === 'function') {
            return () => component;
          }

          return component;
        });
      },
    },
    /**
     * Renders the correct component based on the comparison of flagValue and optionalCondition. flagValue
     * and optionalCondition will be booleans which if then converted into numbers and compared, will
     * indicate which index in the tuple of components to render.
     *
     * @param {object} h (createElement function)
     * @returns {object} the VNode element created by h
     */
    render(h) {
      const index = +(this.flagValue && this.optionalConditionValue);
      const component = this.computedComponents[index];

      return h(component, { props: this.componentProps });
    },
  };
</script>
