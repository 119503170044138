<template>
  <ElTable
    :data="productTableData"
    style="width: 100%"
    lazy
    :row-class-name="tableRowClassName"
    data-test="product table"
  >
    <template #empty>
      Loading Data
    </template>
    <ElTableColumn
      v-bind="{
        prop: 'product',
        label: 'PRODUCTS',
        width: '250',
        showOverflowTooltip: true,
      }"
    >
      <template slot-scope="{ row: { product } }">
        {{ product }}
      </template>
    </ElTableColumn>
    <ElTableColumn
      v-if="projectHasAlts || projectHasRatePass"
      v-bind="{
        width: '100',
      }"
    >
      <template slot-scope="{ row: { product, alternative, ratePass }}">
        <TfBadge
          v-if="alternative"
          :data-test="`${product.toLowerCase()} alternative badge`"
          size="small"
          status="info"
          value="alternate"
        />
        <TfBadge
          v-if="ratePass"
          :data-test="`${product.toLowerCase()} rate pass badge`"
          size="small"
          status="success"
          value="rate pass"
        />
      </template>
    </ElTableColumn>
    <ElTableColumn
      v-bind="{
        prop: 'status',
        label: 'STATUS',
        width: '200',
      }"
    >
      <template slot-scope="{ row: { product, status } }">
        <span :data-test="`${product.toLowerCase()} status`">
          <AppIcon
            :type="status.iconType"
            :icon="status.icon"
          />
          {{ status.status }}
        </span>
      </template>
    </ElTableColumn>
    <ElTableColumn
      v-bind="{
        align: 'right',
        prop: 'policyNumber',
        label: 'POLICY NUMBER',
        width: '150',
      }"
    >
      <template slot-scope="{ row: { product, policyNumber } }">
        <span :data-test="`${product.toLowerCase()} policy number`">
          <ul>
            <li
              v-for="number in policyNumber"
              :key="number"
            >
              {{ number }}
            </li>
          </ul>
        </span>
      </template>
    </ElTableColumn>
    <ElTableColumn
      v-if="isUploadRenewalRatePass"
      v-bind="{
        align: 'right',
        prop: 'renewalDate',
        label: 'RENEWAL DATE',
        width: '130',
      }"
    >
      <template slot-scope="{ row: { product, renewalDate } }">
        <span :data-test="`${product.toLowerCase()} renewal date`">{{ renewalDate }}</span>
      </template>
    </ElTableColumn>
    <ElTableColumn
      width="200"
      v-bind="{
        align: 'left',
        prop: 'rateGuarantee',
        width: '120',
      }"
    >
      <template #header>
        RATE GUARANTEE
        <ElTooltip
          effect="light"
          placement="top"
        >
          <div slot="content">
            Rate guarantee will display here
            <br>
            after your quote is submitted.
          </div>
          <AppIcon icon="fa-regular fa-circle-info" />
        </ElTooltip>
      </template>
      <template slot-scope="{ row: { product, rateGuarantee } }">
        <span :data-test="`${product.toLowerCase()} rate guarantee`">{{ rateGuarantee }}</span>
      </template>
    </ElTableColumn>
    <ElTableColumn
      v-if="isSmartProposal && showPlanDesignProgress"
      v-bind="{
        align: 'left',
        prop: 'planDesign',
        label: 'PLAN DESIGN',
        width: '150',
      }"
      data-test="plan design column"
    >
      <template #header>
        PLAN DESIGN
        <ElTooltip
          effect="light"
          placement="top-start"
          class="vertical-tooltip"
        >
          <template #content>
            The plan design progress bar shows the completion
            <br>
            status of the plan design for this product. You can
            <br>
            submit your quote even if the plan design is incomplete,
            <br>
            provided the rate information is complete.
          </template>
          <AppIcon icon="fa-regular fa-circle-info" />
        </ElTooltip>
      </template>
      <template slot-scope="{ row: { product, percentageComplete } }">
        <div
          :data-test="`${product.toLowerCase()} plan design`"
          class="plan-design-progress"
        >
          <ElProgress
            v-if="percentageComplete <= 0"
            :percentage="percentageComplete"
            :stroke-width="16"
            :show-text="false"
            color="transparent"
            :data-test="`${product.toLowerCase()} progress ${percentageComplete}%`"
          />
          <ElProgress
            v-else
            :percentage="percentageComplete"
            :stroke-width="18"
            :show-text="false"
            color="var(--tf-blue)"
            :data-test="`${product.toLowerCase()} progress ${percentageComplete}%`"
          />

          <div
            v-if="percentageComplete <= 0"
            class="progress-icon"
          >
            <AppIcon icon="fa-regular fa-circle-exclamation" />
          </div>
          <div
            v-else-if="percentageComplete >= 100"
            class="progress-icon"
          >
            <AppIcon icon="fa-regular fa-circle-check" />
          </div>
        </div>
      </template>
    </ElTableColumn>
    <ElTableColumn
      v-bind="{
        prop: 'details',
        label: '',
        width: '168',
        align: 'right',
      }"
    >
      <template slot-scope="{ row: { product, details, status }}">
        <AppButton
          :is-disabled="btnUndoing || !status.rowCtaEnabled"
          :text="status.rowCtaText"
          size="text"
          type="primary"
          :data-test="`${product.toLowerCase()} details button`"
          @click="handleDetails(details.productId, details.state, status)"
        />
      </template>
    </ElTableColumn>
  </ElTable>
</template>

<script>
  import { mapState, mapActions } from 'pinia';
  import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductStore } from '@/stores/product.js';
  import { useProductTableStore } from '@/stores/productTable.js';
  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
  /**
   * Product table for YourQuotes.
   *
   * @exports Product/ProductTable
   */
  export default {
    name: 'ProductTable',
    data: () => ({
      btnUndoing: false,
      showPlanDesignProgress: true,
    }),
    computed: {
      ...mapState(useCarrierInfoStore, {
        carrierId: 'id',
        carrierName: 'name',
      }),
      ...mapState(useProjectStore, [
        'autoRenewActivated',
        'proposalDocument',
        'project',
      ]),
      ...mapState(useProductStore, [
        'isUploadRenewalRatePass',
        'isSmartProposal',
        'smartDocType',
      ]),
      ...mapState(useProductTableStore, [
        'productTableData',
      ]),
      /**
       * Determines if project has any alt products, to determine if the badge column needs to be shown
       *
       * @returns {boolean}
       */
      projectHasAlts() {
        return this.productTableData.some(({ alternative }) => alternative);
      },
      /**
       * Determines if project has any rate passed products to show badge or badge column
       *
       * @returns {boolean}
       */
      projectHasRatePass() {
        return this.productTableData.some(({ ratePass }) => ratePass);
      },
    },
    created() {
      if (this.isSmartProposal) {
        this.fetchPlanDesignCompleteness();
      }
    },
    methods: {
      ...mapActions(useProductStore, ['updateProductState']),
      ...mapActions(useProductTableStore, ['getPlanDesignCompleteness']),
      /**
       * Check whether project product is a renewal and not an alt and autoRenew
       * activated on the proposal document.
       *
       * @returns {boolean}
       */
      isUploadRenewal() {
        const isBaseProduct = !this.product.project_product.alternative;

        return this.autoRenewActivated && isBaseProduct;
      },
      /**
       * Handle route change on click of see details button
       *
       * @param {string} productId
       * @param {string} state
       * @param {object} status
       * @param {string} status.rowCtaText
       */
      async handleDetails(productId, state, { rowCtaText }) {
        if (state === 'declined') {
          // Disable the button until product has been declined.
          this.btnUndoing = true;
          try {
            await this.updateProductState({
              productId,
              endpointState: 'undecline',
            });
          } catch {
            this.$message({
              showClose: true,
              message: 'There was an error undeclining this product. Please try again.',
              type: 'error',
            });
          } finally {
            this.btnUndoing = false;
          }

          return;
        }

        const analyticsCtaText = ['review details', 'enter rates', 'enter rates manually'];

        if (analyticsCtaText.includes(rowCtaText.toLowerCase())) {
          trackSegmentEvent(`Smart ${this.smartDocType(true)} ${rowCtaText} clicked`, {
            product_id: productId,
          });
        }

        const routeName = rowCtaText.toLowerCase() === 'complete plan design' ? 'PlanDesign' : 'RateEntry';

        this.$router.push({
          name: routeName,
          params: {
            productId,
          },
        });
      },
      /**
       * Highlights table row based on status
       *
       * @param {object} root
       * @param {object} root.row
       * @returns {string}
       */
      tableRowClassName({ row }) {
        if (/action needed|ready to submit/i.test(row.status.status)) {
          return 'highlighted';
        }

        return '';
      },
      async fetchPlanDesignCompleteness() {
        this.showPlanDesignProgress = true;
        try {
          await this.getPlanDesignCompleteness(this.proposalDocument.id);
        } catch (error) {
          this.showPlanDesignProgress = false;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
.el-table {
  margin-bottom: 30px;

  :deep(.highlighted) {
    background-color: #f2fffa;
  }

  :deep(td) {
    border-bottom: 1px solid var(--tf-gray-medium);
  }

  :deep(.el-table__header) {
    border-bottom: 1px solid var(--tf-blue);

    th {
      color: var(--tf-blue);
      padding-bottom: 5px;
    }
  }

  .plan-design-progress {
    display: flex;
    align-items: center;
    width: 100px;

    :deep(.el-progress) {
      width: 82px;
    }

    :deep(.el-progress-bar__outer) {
      background-color: var(--tf-gray-medium);
      border-color: var(--tf-gray-medium);
      border-radius: 24px;
    }

    :deep(.el-progress-bar__inner) {
      border-radius: 24px;
    }

    .progress-icon {
      width: 14px;
      height: 14px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      font-weight: bold;
      background-color: #fff;
      color: var(--tf-blue);
      margin-left: 2px;
    }
  }
}
</style>
