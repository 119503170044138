<template>
  <ElForm
    ref="form"
    label-width="100px"
    :model="formModel"
    :rules="formRules"
    :validate-on-rule-change="false"
    :show-message="!isSmartProposal"
    @submit.native.prevent
  >
    <table
      ref="planDesignTable"
      class="table-data-entry"
    >
      <thead>
        <!-- This is to get the widths set for all of the cells -->
        <tr class="row-cell-width">
          <td />
          <td
            v-if="!planDesignIsPlan && isPlanSummary"
            class="cell-classes"
          />
          <template v-if="!isRenewalProduct || isPlanSummary">
            <td
              v-for="cell in subtypeColspan.policy"
              :key="`policy-${cell}`"
              :width="`${cellWidth}px`"
            />
          </template>
          <template v-if="!isPlanSummary">
            <td
              v-if="!planDesignIsPlan"
              class="cell-classes"
            />
            <td
              v-for="cell in subtypeColspan.proposal"
              :key="`proposal-${cell}`"
              :width="`${cellWidth}px`"
            />
          </template>
        </tr>
        <tr
          v-if="!isPlanSummary"
          class="row-logo"
        >
          <td class="cell-empty" />
          <td
            v-if="!isRenewalProduct"
            :colspan="subtypeColspan.policy"
          >
            <h5>
              <img
                class="logo"
                :src="currentProduct.project_product.inforce_product.carrier.logo_url"
                :alt="currentProduct.project_product.inforce_product.carrier.name"
              >
            </h5>
          </td>
          <td
            :colspan="planDesignIsPlan
              ? subtypeColspan.proposal
              : subtypeColspan.proposal + 1"
          >
            <h5>
              <img
                class="logo"
                :src="carrierLogoUrl"
                :alt="carrierName"
              >
            </h5>
          </td>
        </tr>
      </thead>
      <tbody data-test="table body">
        <template v-for="category in categories">
          <tr
            v-if="!ignoreCategories[containerId].includes(category.id)"
            :key="category.name"
            data-test="table header row"
          >
            <th
              class="cell-category-name"
              :colspan="categoryColspan"
              v-text="category.name"
            />
            <template v-if="!planDesignIsPlan">
              <th
                class="cell-label"
                v-text="'Class(es)'"
              />
              <th
                :colspan="subtypeColspan.policy"
                v-html="'&nbsp;'"
              />
            </template>
          </tr>
          <!-- Each Plan Design Attribute -->
          <template v-if="planDesignIsPlan">
            <template
              v-for="(attributeGrouping, groupIdx) in category.plan_design_attribute_groups"
            >
              <PlanDesignCategoryTitle
                :key="`${category.name}-${groupIdx}-title`"
                :subtypes="attributeGrouping.subtypes_by_container_and_product"
              />
              <template v-for="(attribute, idx) in attributeGrouping.plan_design_attributes">
                <PlanDesignAttribute
                  v-if="attribute.name !== 'Rate Guarantee'"
                  :key="`${category.name}-${attribute.name}-${idx}`"
                  :attribute="attribute"
                  :attribute-id="attribute.id"
                  :attribute-name="attribute.name"
                  :category-id="category.id"
                  :idx="idx"
                  data-test="table row"
                  @planDesignErrorsUpdated="$nextTick(getInvalidFields);"
                />
              </template>
            </template>
          </template>
          <template v-else>
            <template v-for="attribute in category.plan_design_attributes">
              <template v-if="attribute.name !== 'Rate Guarantee'">
                <PlanDesignAttribute
                  v-for="(attributeGrouping, idx) in attribute.groupings"
                  :key="`${category.name}-${attribute.name}-${idx}`"
                  :attribute="attributeGrouping"
                  :attribute-id="attribute.id"
                  :attribute-name="attribute.name"
                  :category-id="category.id"
                  :idx="idx"
                  :groupings="attribute.groupings.length"
                  @planDesignErrorsUpdated="$nextTick(getInvalidFields);"
                />
              </template>
            </template>
          </template>
        </template>
      </tbody>
    </table>
  </ElForm>
</template>

<script>
  import { mapState, mapWritableState } from 'pinia';
  import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
  import { useProductStore } from '@/stores/product.js';
  import { usePlanDesignStore } from '@/stores/planDesign.js';
  import { smartProposals, uploadRenewalUploadEnhancements } from '@/utils/featureFlags.js';
  import PlanDesignAttribute from './PlanDesignAttribute.vue';
  import PlanDesignCategoryTitle from './PlanDesignCategoryTitle.vue';

  /**
   * Plan Design Table
   *
   * @exports PlanDesign/PlanDesignTable
   */
  export default {
    name: 'PlanDesignTable',
    components: {
      PlanDesignAttribute,
      PlanDesignCategoryTitle,
    },
    inject: [
      'containerId',
      'isPlanSummary',
      'subtypeColspan',
    ],
    data() {
      return {
        planDesignTableWidth: 0,
      };
    },
    computed: {
      ...mapState(usePlanDesignStore, [
        'planDesignIsPlan',
        'ignoreCategories',
        'info',
        'values',
      ]),
      ...mapState(useCarrierInfoStore, {
        carrierName: 'name',
        carrierLogoUrl: 'logoUrl',
      }),
      ...mapState(useProductStore, [
        'isUploadRenewalRatePassOrSmartProposal',
        'currentProduct',
        'isNewCoverage',
        'isRenewalProduct',
        'isUploadRenewalRatePassOrSmartProposal',
        'isSmartProposal',
      ]),
      ...mapWritableState(usePlanDesignStore, ['planDesignErrors']),
      /**
       * Evaluate the uploadRenewalUploadEnhancements feature flag.
       *
       * @returns {boolean}
       */
      uploadRenewalUploadEnhancementsEnabled() {
        return this.$ld.checkFlags(uploadRenewalUploadEnhancements);
      },
      /**
       * Evaluate the uploadRenewalUploadEnhancements feature flag.
       *
       * @returns {boolean}
       */
      smartProposalEnabled() {
        return this.$ld.checkFlags(smartProposals);
      },
      /**
       * Categories to loop through for layout.
       *
       * @returns {Array}
       */
      categories() {
        if (!this.planDesignIsPlan) {
          return this.info.categories;
        }

        return this.info.containers
          .find((container) => container.id === this.containerId).categories;
      },
      /**
       * Determines the category colspan based on subtypeColpan and documentType.
       *
       * @returns {number}
       */
      categoryColspan() {
        if (this.isPlanSummary) {
          return this.planDesignIsPlan
            ? 1 + this.subtypeColspan.policy // plan design on plan summary should only include policy colspan
            : 1;
        }

        if (this.planDesignIsPlan) {
          // Needs to return the full col count since it goes across the full table
          return this.isRenewalProduct
            ? 1 + this.subtypeColspan.proposal // attribute name + proposal subtype count
            : 1 + Object.values(this.subtypeColspan).reduce((a, b) => a + b); // attribute name + policy subtype count + proposal subtype count
        }

        // Needs to return the full col count
        return this.isRenewalProduct || this.isNewCoverage
          ? 1 // only 1 colspan since there is no col for policy
          : 1 + this.subtypeColspan.proposal; // attribute name + policy subtype count
      },
      /**
       * Calculates the width of each non-title cell.
       * Attribute names are 250px wide and the class label cell for class based products is 100px.
       *
       * @returns {number}
       */
      cellWidth() {
        const fixedWidth = this.planDesignIsPlan ? 250 : 350;
        let divisibleBy = this.subtypeColspan.policy;

        if (!this.isPlanSummary) {
          divisibleBy += this.subtypeColspan.proposal;
        }

        return (this.planDesignTableWidth - fixedWidth) / divisibleBy;
      },
      tableRows() {
        const tableRows = this.categories
          .map((category) => {
            const attrs = [];

            if (category.plan_design_attributes) {
              category.plan_design_attributes.forEach(({ id, groupings }) => {
                groupings.forEach(({ ids, names, products }) => {
                  if (Array.isArray(names)) {
                    if (products.some(({ tier_group: tierGroup }) => tierGroup && tierGroup.id)) {
                      products.forEach(({ tier_group: tierGroup }) => {
                        tierGroup.tier_subtypes.forEach(({ id: subtypeId }) => {
                          attrs.push([
                            `${ids.join('_')}_${category.id}_${id}_${tierGroup.id}_${subtypeId}`,
                            this.values[`${ids.join('_')}_${category.id}_${id}_${tierGroup.id}_${subtypeId}`]?.value,
                          ]);
                        });
                      });
                    } else {
                      attrs.push([
                        `${ids.join('_')}_${category.id}_${id}`,
                        this.values[`${ids.join('_')}_${category.id}_${id}`]?.value,
                      ]);
                    }
                  } else {
                    attrs.push([
                      `${ids.join('_')}_${category.id}_${id}`,
                      this.values[`${ids.join('_')}_${category.id}_${id}`]?.value,
                    ]);
                  }
                });
              });
            } else {
              category.plan_design_attribute_groups.forEach(({ plan_design_attributes: attributes }) => {
                attributes.forEach(({ id, products }) => {
                  if (products.some(({ tier_group: tierGroup }) => tierGroup && tierGroup.id)) {
                    products.forEach(({ tier_group: tierGroup }) => {
                      tierGroup.tier_subtypes.forEach(({ id: subtypeId }) => {
                        attrs.push([
                          `${this.containerId}_${category.id}_${id}_${tierGroup.id}_${subtypeId}`,
                          this.values[`${this.containerId}_${category.id}_${id}_${tierGroup.id}_${subtypeId}`]?.value,
                        ]);
                      });
                    });
                  } else {
                    attrs.push([`${this.containerId}_${category.id}_${id}`, this.values[`${this.containerId}_${category.id}_${id}`]?.value]);
                  }
                });
              });
            }

            return attrs;
          });

        return tableRows.flat();
      },
      /**
       * Form model for validation against
       *
       * @returns {object}
       */
      formModel() {
        return Object.fromEntries(this.tableRows);
      },
      /**
       * Form validation rules
       *
       * @returns {object}
       */
      formRules() {
        const formRules = {};

        Object.keys(this.formModel).forEach((key) => {
          formRules[key] = [{
            required: true,
            message: 'Value must be provided',
            trigger: ['blur', 'change'],
          }];
        });

        return formRules;
      },
    },
    /**
     * set planDesignTableWidth
     */
    mounted() {
      this.planDesignTableWidth = this.$refs.planDesignTable.clientWidth;
    },
    created() {
      // Set up the form validations
      this.planDesignErrors.splice(0, this.planDesignErrors.length);
      this.$nextTick(this.getInvalidFields);
    },
    methods: {
      /**
       * Configure the rate entry ElForm instance to use validation.
       * Populate planDesignErrors in the store with a list of component refs
       */
      getInvalidFields() {
        if (!this.isUploadRenewalRatePassOrSmartProposal) {
          return;
        }

        /**
         * Populate/sync planDesignErrors with the list of ElFormItem elements with validation errors
         */
        this.$refs.form.validate((valid, invalid) => {
          const existingErrorFields = this.planDesignErrors.map((field) => field());
          /**
           * After we validate the form we refresh planDesignErrors with invalid fields
           */
          const fieldsCopy = [...this.$refs.form.fields];

          fieldsCopy.sort((a, b) => {
            const indexA = this.tableRows.findIndex(([row]) => row === a.prop);
            const indexB = this.tableRows.findIndex(([row]) => row === b.prop);

            if (indexA < indexB) return -1;
            if (indexB < indexA) return 1;

            return 0;
          });

          const invalidFields = fieldsCopy.filter(
            ({ prop }) => Object.keys(invalid).includes(prop),
          ).map(({ $el }) => $el.querySelector('input, textarea'));
          const formFields = this.$refs.form.fields.map(({ $el }) => $el.querySelector('input, textarea'));

          if (!invalidFields.every((field) => existingErrorFields.includes(field))) {
            const updatedErrors = this.planDesignErrors.filter((field) => !formFields.includes(field()));

            this.planDesignErrors.splice(
              0,
              this.planDesignErrors.length,
              ...updatedErrors,
              ...invalidFields.map((field) => () => field),
            );
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
.cell-label {
  padding: 0;
  text-align: center;
}

.logo {
  display: block;
  height: 60px;
  margin: 0 auto;
}

.row-logo {
  td:not(.cell-empty) {
    border: 1px solid $tf-extra-light-gray-1;
    border-bottom: none;
    box-shadow: none;

    &:first-child {
      border-radius: 5px 0 0;
    }
  }
}

form {
  margin: 0 30px;

  .dialog-plan-summary & {
    margin: 0;
  }
}

table {
  width: 100%;
  margin-bottom: 20px;
  table-layout: fixed;

  /* The first td of its type is in the header w/ the icons */
  /* stylelint-disable declaration-no-important */
  .row-cell-width {
    td {
      padding: 0 !important;
      border: none;

      &:first-of-type {
        width: 250px;
      }

      &.cell-classes {
        width: 100px;
      }
    }
  }
  /* stylelint-enable declaration-no-important */
}

tbody {
  border: 1px solid $tf-extra-light-gray-1;
}

th {
  height: 44px;
  padding: {
    left: 6px;
    right: 6px;
  }
  background: $tf-extra-light-gray-2;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  border: {
    top: 1px solid $tf-extra-light-gray-1;
    bottom: 1px solid $tf-extra-light-gray-1;
  }

  &.cell-category-name {
    padding-left: 20px;
  }

  &:first-child {
    border-left: 1px solid $tf-extra-light-gray-1;
  }

  &:last-child {
    border-right: 1px solid $tf-extra-light-gray-1;
  }
}

h5 {
  display: flex;
  align-items: center;
  height: 80px;
  margin-bottom: 0;
}
</style>
