<template>
  <section
    ref="viewer"
    class="file-viewer"
  >
    <div
      v-if="proposals && proposals.length"
      v-loading="loading"
      class="tool-bar"
    >
      <ElSelect
        :value="selectedDocumentId"
        popper-class="file-select-dropdown"
        placeholder="Select file"
        data-test="select file"
        @change="changeSelectedDocumentId"
      >
        <ElOption
          class="manage-upload-option"
          value="manage-proposals"
          data-test="manage or upload documents"
          label="Manage or upload documents"
        >
          Manage or upload documents
          <span
            class="upload-icon"
          >
            <AppIcon icon="fa-solid fa-file-upload" />
          </span>
        </ElOption>
        <ElOption
          v-for="proposal in proposals"
          :key="proposal.id"
          data-test="proposal option"
          :label="proposal.name"
          :value="proposal.id"
        />
      </ElSelect>
      <div
        v-if="loaded && selectedDocumentId"
        class="pdf-controls"
      >
        <AppButton
          icon="fa-solid fa-minus"
          size="icon-small"
          data-test="decrease zoom"
          @click="decreaseZoom"
        />
        <span
          data-test="percentage"
          class="percentage"
        >{{ parseInt(scale * 100) }}%</span>
        <AppButton
          icon="fa-solid fa-plus"
          size="icon-small"
          data-test="increase zoom"
          @click="increaseZoom"
        />
      </div>
    </div>
    <div
      v-if="proposals && !proposals.length"
      class="empty-state"
    >
      <img
        :src="emptyIcon"
        alt="No proposals icon"
      >
      <h3 data-test="upload documents header">
        Upload additional documents.
      </h3>
      <p>
        While not mandatory, uploading proposal documents or additional marketing materials can
        serve as a helpful reference for your broker partner.
      </p>
      <AppButton
        text="Upload documents"
        data-test="upload documents"
        @click="onUploadProposal"
      />
    </div>
    <div
      v-show="loaded && selectedDocumentId && numPages !== null"
      class="pdf-container"
      data-test="pdf container"
    >
      <canvas
        v-for="(page, index) in numPages"
        :key="index"
        :ref="`page-${index}`"
      />
    </div>
    <ElAlert
      v-if="errorMessage"
      :title="errorMessage"
      :closable="false"
      show-icon
      type="error"
    />
    <ProposalManagerModal
      v-if="isModalSupplementalDocumentsOpen && proposalDocument"
      :visible.sync="isModalSupplementalDocumentsOpen"
      :proposals="proposals"
      append-to-body
    />
  </section>
</template>

<script>
  import { mapState } from 'pinia';
  import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist/build/pdf.js';
  import emptyIcon from '@/assets/upload-files.svg';
  import ProposalManagerModal from '@/components/Modals/ProposalManagerModal.vue';
  import { useProjectStore } from '@/stores/project.js';
  import { config } from '@/utils/config.js';

  const apiConfig = {
    baseUrl: config.VUE_APP_API_URL,
    cookieNamespace: config.VUE_APP_COOKIE_NAMESPACE,
  };

  /**
   * Proposal/Source File Viewer
   *
   * @exports FileViewer
   */
  export default {
    name: 'FileViewer',
    components: { ProposalManagerModal },
    data: () => ({
      selectedDocumentId: null,
      source: '',
      emptyIcon,
      errorMessage: null,
      loaded: false,
      loading: false,
      numPages: null,
      pdfLoadingTask: null,
      scale: null,
      isModalSupplementalDocumentsOpen: false,
    }),

    computed: {
      ...mapState(useProjectStore, ['proposals', 'proposalDocument']),
    },
    /**
     * destroy any potential PDFs currently loading
     */
    beforeDestroy() {
      if (this.pdfLoadingTask) {
        this.pdfLoadingTask.destroy();
      }
    },
    async beforeCreate() {
      const pdfjsLib = await import('pdfjs-dist/build/pdf.worker.entry.js');

      GlobalWorkerOptions.workerSrc = pdfjsLib;
    },

    methods: {
      /**
       * changes the selectedDocumentId depending on the value coming from change event
       *
       * @param {string} selection
       */
      changeSelectedDocumentId(selection) {
        if (selection === 'manage-proposals') {
          this.onUploadProposal();

          return;
        }

        this.selectedDocumentId = selection;
        this.getPDFSource();
      },
      /**
       * Decrease the zoom on the pdfs
       */
      decreaseZoom() {
        this.scale -= 0.1;
        this.renderPdf();
      },
      /**
       * Get the PDFsource for the current File
       */
      getPDFSource() {
        this.loading = true;
        this.errorMessage = null;
        this.numPages = null;
        this.$TF.SourceService
          .getSource(
            this.selectedDocumentId,
            'carrier_portal',
            apiConfig,
          )
          .then((response) => {
            // call the api to get the data for this file
            this.source = window.atob(response.data.source.content.replace(/\s/g, ''));
            this.scale = null;
            // encrypt the file
            // then call pdfjs
            if (this.pdfLoadingTask) {
              this.pdfLoadingTask.destroy().then(() => {
                this.renderPdf();
              });

              return;
            }

            this.renderPdf();
          })
          .catch(() => {
            this.loading = false;
            this.errorMessage = 'There was an error loading the document.';
          });
      },
      /**
       * Increase the zoom on the pdfs
       */
      increaseZoom() {
        this.scale += 0.1;
        this.renderPdf();
      },
      /**
       * If you click the upload proposal button
       */
      onUploadProposal() {
        this.isModalSupplementalDocumentsOpen = true;
      },
      /**
       * Render the selected pdf with pdfjs
       */
      async renderPdf() {
        try {
          const pdf = await getDocument({ data: this.source }).promise;

          // turn of the loading gif
          this.numPages = pdf.numPages;
          const self = this;

          for (let i = 0; i < pdf.numPages; i += 1) {
            // loop through each page individually and populate a canvas with each page
            pdf.getPage(i + 1).then((page) => {
              const canvas = self.$refs[`page-${i}`][0];

              if (!this.scale) {
                this.scale = canvas.offsetWidth / canvas.width;
              }
              const viewport = page.getViewport(this.scale);
              const { viewBox } = viewport;
              const context = canvas.getContext('2d');

              [, , canvas.width, canvas.height] = viewBox;

              const renderContext = {
                canvasContext: context,
                viewport,
              };

              page.render(renderContext);
            });
          }
          this.loaded = true;
          this.loading = false;
        } catch {
          this.$message({
            message: 'There was an error loading the document.',
            type: 'error',
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .file-viewer {
    padding-left: 10px;
  }

  .source-loading {
    height: 100%;
  }

  section {
    background: #fff;
    width: 100%;
    height: 100%;
  }

  :deep(.el-select) {
    width: 239px;
  }

  .pdf-container {
    position: relative;
    z-index: 1;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: scroll;
  }

  .percentage {
    display: flex;
    align-items: center;
    padding: 4px 13px;
    font-size: 14px;
    color: $tf-dark-gray;
    border: 1px solid $tf-light-gray;
    border-radius: 2px;
  }

  .pdf-controls {
    display: flex;
    margin-left: auto;
  }

  .tool-bar {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;
    height: 46px;
    padding: 0 16px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .25),
      inset 0 1px 0 0 $tf-extra-light-gray-2,
      inset 0 -1px 0 0 $tf-extra-light-gray-2;
  }

  .el-alert {
    margin-top: 25px;
  }

  .empty-state {
    background-color: $tf-light-blue;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 105px;
      height: 105px;
    }

    h3,
    p {
      max-width: 385px;
    }

    h3 {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.2;
      color: $tf-dark-gray;
    }

    p {
      color: $tf-dark-gray;
      line-height: 1.29;
      padding: 0 25px;
      margin: 11px 0 23px;
    }
  }

  canvas {
    rotate: x 180deg;
  }
</style>

<style lang="scss">
  .source-loading .el-loading-mask {
    z-index: 1;
  }

  .file-select-dropdown {
    // we transform the element to the right to align the right sides of it and the select input
    // the 100% - [width of select input] is necessary since the dropdown width is dynamic
    transform: translateX(calc(100% - 241px));

    .manage-upload-option {
      color: $tf-blue;
      border-bottom: 1px solid $tf-blue;

      svg {
        height: 15px;
        width: 15px;
        margin-left: 3px;
        position: relative;
        top: 2px;
      }
    }
  }
</style>
