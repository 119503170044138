<template>
  <div
    v-loading="loadingPlanDesign"
    class="data-entry-landing"
  >
    <template v-if="info">
      <div
        class="data-entry-containers"
        data-test="entry container"
      >
        <template v-if="planDesignIsPlan">
          <PlanDesignContainer
            v-for="(container, containerIndex) in containers"
            :key="container.id"
            :container="container"
            :warning-message="!containerIndex && warningMessage ? warningMessage : null"
            @dismissAlert="onDismissAlert"
          />
        </template>
        <PlanDesignContainer
          v-else
          :warning-message="warningMessage"
          @dismissAlert="onDismissAlert"
        />
      </div>
      <footer>
        <div>
          <div class="absolute-group">
            <AppButton
              v-if="allowUserPreferences"
              :is-disabled="!canSavePredefinedValues"
              data-test="submit user default values"
              icon="fa-solid fa-check-circle"
              text="Save plan design preferences"
              type="success"
              prepend
              @click="ffUserDefaultsNotifications
                ? toggleShowUserDefaultModal()
                : submitPreferredPlanDesignValues()"
            />
          </div>
          <div class="btn-group">
            <RouterLink :to="{ name: 'RfpOverview' }">
              <AppButton
                type="secondary"
                data-test="finish later"
                :text="$route.meta.readonly ? 'Done' : 'Finish later'"
              />
            </RouterLink>
            <RouterLink :to="{name: $route.meta.readonly ? 'RateEntryReview' : 'RateEntry',}">
              <AppButton
                :is-disabled="!canContinue"
                data-test="continue"
                icon="fa-solid fa-chevron-right"
                text="Continue to rate entry"
                type="primary"
              />
            </RouterLink>
          </div>
        </div>
        <UserDefaultModal
          v-if="ffUserDefaultsNotifications"
          :visible="showUserDefaultModal"
          :product-id="currentProduct.product_type_id"
          :product-name="currentProduct.product_type_name"
          :user-preferred-values="userPreferredPlanDesignValues"
          @closeDialog="toggleShowUserDefaultModal"
        />
      </footer>
    </template>
  </div>
</template>

<script>
  import { mapActions, mapState, mapWritableState } from 'pinia';
  import {
    saveUserDefaultPlanDesignValues,
    userDefaultsNotifications,
    benefitCalloutComments,
  } from '@/utils/featureFlags.js';
  // Pinia Stores
  import { useCallouts } from '@/stores/callouts.js';
  import { useUserPreferredPdvsStore } from '@/stores/userPreferredPlanDesignValues.js';
  import { useNotificationsStore } from '@/stores/notifications.js';
  import useContainerStore from '@/stores/planDesign/containers.js';
  import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
  import { useAccountStore } from '@/stores/account.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductStore } from '@/stores/product.js';
  import { usePlanDesignStore } from '@/stores/planDesign.js';
  // Components
  import PlanDesignContainer from '@/components/PlanDesign/PlanDesignContainer.vue';
  import UserDefaultModal from '@/components/Modals/UserDefaultModal.vue';

  /**
   * Plan Design for the product page.
   *
   * @exports ProductLanding/PlanDesignLanding
   */
  export default {
    name: 'PlanDesignLanding',
    components: { PlanDesignContainer, UserDefaultModal },
    data: () => ({
      destroyProductId: null,
      showUserDefaultModal: false,
    }),

    computed: {
      ...mapWritableState(useNotificationsStore, ['reviewAlertDismissed']),
      ...mapState(useContainerStore, ['containers']),
      ...mapState(useCarrierInfoStore, { carrierName: 'name', carrierId: 'id' }),
      ...mapState(useAccountStore, [
        'userInfo',
        'planDesignProductsVisited',
      ]),
      ...mapState(useUserPreferredPdvsStore, ['userPreferredPlanDesignValues', 'productsWithPreferredPdvs']),
      ...mapState(useProjectStore, ['isRenewalProject', 'autoRenewActivated']),
      ...mapState(useProductStore, [
        'currentProduct',
        'isNewCoverage',
        'isRenewalProduct',
        'isStopLoss',
        'productId',
        'currentProductPreviouslySubmitted',
        'completedStopLossProducts',
      ]),
      ...mapState(usePlanDesignStore, [
        'planDesignIsPlan',
        'validPlanDesign',
        'loadingPlanDesign',
        'info',
        'values',
      ]),

      /**
       * Used to enable the continue button if all plan design values not being patched or value is blank
       *
       * @returns {boolean}
       */
      canContinue() {
        // rate guarantee isn't added to value so no need to filter when it is plan based
        if (this.planDesignIsPlan) {
          return !Object.keys(this.values)
            .map((value) => this.values[value].value || null).includes(null);
        }
        // we're filtering out Rate Guarantee since it is technically a value that exists inside the plan design values.
        // Rate Guarantee is filled out on the following Rate Entry page, therefore we don't want to let its null value
        // stop us from continuing into the next page since it will always be null at this step for any new quote.
        const financialCategory = this.info.categories.find(({ name }) => name === 'Financial');
        const rateGuaranteeAttributeId = financialCategory.plan_design_attributes.find(({ name }) => name === 'Rate Guarantee').id;
        const planDesignValuesNoRateGuarantee = Object.entries(this.values).filter(
          ([key]) => (this.values[key])
            .plan_design_attribute_id !== rateGuaranteeAttributeId,
        ).map(([, value]) => value);

        return !Object.keys(planDesignValuesNoRateGuarantee)
          .map((value) => planDesignValuesNoRateGuarantee[value].value || null).includes(null);
      },
      /**
       * returns true if any product in completedStopLossProducts matches `productId`
       *
       * @returns {boolean}
       */
      hasCompletedStopLossProducts() {
        return this.completedStopLossProducts.some((product) => product.id !== this.productId);
      },

      /**
       * Determines if the user has visited this product.
       *
       * @returns {string}
       */
      warningMessage() {
        let warningMessage;

        if (this.currentProduct.state === 'completed' && !this.reviewAlertDismissed) {
          warningMessage = 'Making changes to your entries will remove your original quote from the broker’s view. Your quote will become visible to the broker again once you submit your updates.';
        } else if (!this.currentProductPreviouslySubmitted && !this.hasVisitedProduct) {
          // IF renewal product:
          // ELSE IF non-stop loss product alternative
          // ELSE base/alternative stop loss && takeover products)
          if (this.isRenewalProduct) {
            warningMessage = 'We have pre-populated your renewal quote based on your in-force policy.';
          } else if (
            this.isNewCoverage
            && this.currentProduct.project_product.alternative
            && !this.isStopLoss
          ) {
            warningMessage = 'This is a plan alternative. To get you started, we’ve built a plan design based on the broker’s request.';
          } else {
            warningMessage = `We’ve applied ${this.carrierName}’s standard language to the plan design.`;
          }

          if (
            this.isStopLoss
            && this.hasCompletedStopLossProducts
          ) {
            warningMessage += ' Copy your previously submitted quote using the drop down to the right.';
          }

          warningMessage += ' Please review, refine, and confirm your entries before submission.';
        }

        // Auto Renewal warning takes precedence over all other warnings
        const isAutoRenew = this.autoRenewActivated;
        const isBaseProduct = !this.currentProduct.project_product.alternative;
        const isInProgress = !['not_started', 'completed', 'declined'].includes(this.currentProduct.state);

        if (isAutoRenew && isBaseProduct && isInProgress) {
          warningMessage = 'Your renewal is in progress and will be ready to review within 2 business days of uploading files. If you need to replace your previous uploaded files, that will restart the 2 business day processing time.';
        }

        return warningMessage;
      },

      /**
       * Determines if the user has visited this product.
       *
       * @returns {boolean}
       */
      hasVisitedProduct() {
        return this.planDesignProductsVisited.includes(this.productId);
      },

      /**
       * Determines if we let users have/use plan design preferences
       *
       * @returns {boolean}
       */
      allowUserPreferences() {
        const isRenewal = this.isRenewalProject || this.isRenewalProduct;

        return this.ffSaveUserDefaultPlanDesignValues && !this.$route.meta.readonly && !isRenewal;
      },
      /**
       * Evaluates the saveUserDefaultPlanDesignValues feature flag
       *
       * @returns {boolean}
       */
      ffSaveUserDefaultPlanDesignValues() {
        return this.$ld.checkFlags(saveUserDefaultPlanDesignValues);
      },
      /**
       * Evaluates the userDefaultsNotifications feature flag
       *
       * @returns {boolean}
       */
      ffUserDefaultsNotifications() {
        return this.$ld.checkFlags(userDefaultsNotifications);
      },
      /**
       * Allows the user to save the list of predefined values
       *
       * @returns {boolean}
       */
      canSavePredefinedValues() {
        return !!Object.keys(this.userPreferredPlanDesignValues).length;
      },
    },

    /**
     * get plan design and set destroyProductId
     */
    async mounted() {
      if (
        this.currentProduct.state === 'not_started'
        && this.productsWithPreferredPdvs.includes(this.currentProduct.product_type_id)
        && this.allowUserPreferences
      ) {
        await this.applyUserPreferredValues(this.productId);

        if (this.ffUserDefaultsNotifications) {
          this.$message({
            showClose: true,
            message: 'We\'ve applied your plan design preferences. Please review, refine, and confirm your entries before submission. You can edit and save over these values at any time.',
            type: 'warning',
          });
        }
      }

      this.loadCallouts([this.productId], this.$ld.checkFlags(benefitCalloutComments));

      // destroyProductId needs to be set as the productId to be used in the beforeDestroy lifecycle.
      this.destroyProductId = this.productId;
    },
    /**
     * There is a timing issue where productId is destroyed before being used.
     * `destroyProductId` is used instead. destroyProductId needs to be set
     * as the productId to be used in the beforeDestroy lifecycle.
     */
    beforeDestroy() {
      // Validate the Plan Design on destroy instead of covering every page exit
      // Only do this when clicking on Submit or if they've filled out the form
      // and then left the page w/o clicking continue. This is sort of edge casey but want to cover it.
      if (this.canSubmit && !this.validPlanDesign) {
        this.validatePlanDesign(this.destroyProductId)
          // We are ignoring any error state here b/c we don't want to show anything
          // But we want to avoid uncaught promises
          .catch(() => {});
      }
      // Clear all the Plan Design things
      this.resetUserPreferredPdvs();
    },
    methods: {
      ...mapActions(usePlanDesignStore, [
        'clearPlanDesign',
        'getPlanDesign',
        'applyUserPreferredValues',
        'validatePlanDesign',
      ]),
      ...mapActions(useCallouts, ['loadCallouts']),
      ...mapActions(useUserPreferredPdvsStore, ['resetUserPreferredPdvs', 'savePreferredPlanDesignValues']),

      /**
       * sets `reviewAlertDismissed` to true if displayAlertReview is true.
       */
      onDismissAlert() {
        this.reviewAlertDismissed = true;
      },
      /**
       * Saves the users changed plan design values as their defaults
       * for future projects of the same type
       */
      async submitPreferredPlanDesignValues() {
        try {
          await this.savePreferredPlanDesignValues(
            this.info.productTypeId,
            this.userPreferredPlanDesignValues,
          );

          this.$message({
            showClose: true,
            message: 'You’ve successfully saved your plan design preferences for this product. You can edit and save over these values at any time.',
            type: 'success',
          });
        } catch {
          this.$message({
            showClose: true,
            message: 'There was an error saving the plan design preferences. Please try again.',
            type: 'error',
          });
        }
      },
      /**
       * Toggles modal visibilty
       */
      toggleShowUserDefaultModal() {
        this.showUserDefaultModal = !this.showUserDefaultModal;
      },
    },
  };
</script>

<style scoped>
.btn-group {
  gap: var(--tf-btn-margin-medium);
  height: 32px;
}

.absolute-group {
  position: absolute;
  left: 40px;
}
</style>
