<template>
  <div
    v-loading="loadingPlanDesign"
    class="data-entry-landing"
  >
    <template v-if="info">
      <div
        class="data-entry-containers"
        data-test="entry container"
      >
        <template v-if="planDesignIsPlan">
          <PlanDesignContainer
            v-for="(container, containerIndex) in containers"
            :key="container.id"
            data-test="plan design container"
            :allow-user-preferences="allowUserPreferences"
            :container="container"
            :warning-message="!containerIndex && warningMessage ? warningMessage : null"
            @dismissAlert="onDismissAlert"
            @savePreferences="savePreferences"
          />
        </template>
        <PlanDesignContainer
          v-else
          data-test="non plan design container"
          :allow-user-preferences="allowUserPreferences"
          :warning-message="warningMessage"
          @dismissAlert="onDismissAlert"
          @savePreferences="savePreferences"
        />
      </div>
      <footer class="renewal-footer">
        <div class="btn-group">
          <AppButton
            text="Your Quotes"
            data-test="finish later"
            icon="fa-solid fa-circle-left"
            class="icon-left"
            @click="sendYourQuotesAnalytics"
          />
          <AppButton
            v-if="showFinishedButton"
            text="Finished"
            data-test="finish plan design"
            class="finish-button"
            @click="showSendAltsModal = true"
          />
          <template v-else>
            <AppButton
              v-if="showReviewRatesSecondaryButton"
              text="Review Rates"
              data-test="continue secondary"
              @click="() => $router.push({ name: 'RateEntry' })"
            />
            <AppButton
              v-if="showSubmitQuoteSecondaryButton"
              text="Submit Quote"
              data-test="submit quote secondary"
              :is-disabled="!isSmartProposal && !isPlanDesignValid"
              @click="handleSubmitQuote"
            />
          </template>
        </div>
        <div
          data-test="stepper helptext"
          class="stepper-helptext"
        >
          {{ planDesignErrorCountText }}
        </div>
        <div class="btn-group">
          <AppButton
            v-if="$route.meta.readonly"
            icon="check"
            text="Done"
            @click="$router.push({ name: 'RfpOverview' })"
          />
          <AppButton
            v-else-if="isSmartProposal && !isPlanDesignValid"
            text="Next"
            data-test="next error button"
            class="submit-button"
            icon="fa-solid fa-circle-right"
            @click="() => nextError()"
          />
          <template v-else>
            <template v-if="!isRateEntryValid">
              <RouterLink :to="{name: $route.meta.readonly ? 'RateEntryReview' : 'RateEntry',}">
                <AppButton
                  data-test="continue"
                  class="submit-button"
                  icon="fa-solid fa-circle-right"
                  text="Review Rates"
                  type="primary"
                />
              </RouterLink>
            </template>
            <AppButton
              v-else
              text="Submit Quote"
              data-test="submit quote"
              class="submit-button"
              icon="fa-solid fa-circle-right"
              :is-disabled="!isSmartProposal && !isPlanDesignValid"
              @click="handleSubmitQuote"
            />
          </template>
        </div>
      </footer>
      <UserDefaultModal
        v-if="ffUserDefaultsNotifications"
        data-test="user default modal"
        :visible="showUserDefaultModal"
        :product-id="currentProduct.product_type_id"
        :product-name="currentProduct.product_type_name"
        :user-preferred-values="userPreferredPlanDesignValues"
        @closeDialog="toggleShowUserDefaultModal"
      />
      <SendAltsToCarrierModal
        v-if="showSendAltsModal"
        data-test="send alts modal"
        :product-id="productId"
        :visible.sync="showSendAltsModal"
      />
    </template>
  </div>
</template>

<script>
  import { mapActions, mapState, mapWritableState } from 'pinia';
  import {
    saveUserDefaultPlanDesignValues,
    userDefaultsNotifications,
    benefitCalloutComments,
    uploadRenewalUploadEnhancements,
  } from '@/utils/featureFlags.js';
  import { arrayHasItem } from '@/utils/general.js';
  import { tfAssistAndDiveUnsupportedProductTypes } from '@/utils/product.js';
  // Pinia Stores
  import { useCallouts } from '@/stores/callouts.js';
  import { useUserPreferredPdvsStore } from '@/stores/userPreferredPlanDesignValues.js';
  import { useNotificationsStore } from '@/stores/notifications.js';
  import useContainerStore from '@/stores/planDesign/containers.js';
  import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
  import { useAccountStore } from '@/stores/account.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductStore } from '@/stores/product.js';
  import { useProductTableStore } from '@/stores/productTable.js';
  import { usePlanDesignStore } from '@/stores/planDesign.js';
  import { useRateEntryStore } from '@/stores/rateEntry.js';
  // Components
  import PlanDesignContainer from '@/components/PlanDesign/PlanDesignContainer.vue';
  import UserDefaultModal from '@/components/Modals/UserDefaultModal.vue';
  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
  import SendAltsToCarrierModal from '@/components/Modals/SendAltsToCarrierModal.vue';

  /**
   * Plan Design for the product page.
   *
   * @exports ProductLanding/PlanDesignLanding
   */
  export default {
    name: 'PlanDesignRenewalLanding',
    components: { PlanDesignContainer, UserDefaultModal, SendAltsToCarrierModal },
    data: () => ({
      destroyProductId: null,
      showUserDefaultModal: false,
      validatingProduct: false,
      currentError: -1,
      fieldSwitchTimer: null,
      showSendAltsModal: false,
      warningMessage: null,
    }),

    computed: {
      ...mapWritableState(useNotificationsStore, ['reviewAlertDismissed']),
      ...mapWritableState(usePlanDesignStore, ['planDesignErrors']),
      ...mapState(useContainerStore, ['containers']),
      ...mapState(useCarrierInfoStore, { carrierName: 'name', carrierId: 'id' }),
      ...mapState(useAccountStore, ['userInfo', 'roleNames', 'planDesignProductsVisited']),
      ...mapState(useUserPreferredPdvsStore, [
        'userPreferredPlanDesignValues',
        'productsWithPreferredPdvs',
      ]),
      ...mapState(useUserPreferredPdvsStore, ['userPreferredPlanDesignValues', 'productsWithPreferredPdvs']),
      ...mapState(useProjectStore, [
        'projectId',
        'proposalDocumentId',
        'isRenewalProject',
        'autoRenewActivated',
      ]),
      ...mapState(useProductStore, [
        'currentProduct',
        'isNewCoverage',
        'isRenewalProduct',
        'isStopLoss',
        'productId',
        'currentProductPreviouslySubmitted',
        'completedStopLossProducts',
        'isSmartProposal',
        'isUploadRenewalRatePassOrSmartProposal',
        'smartDocType',
      ]),
      ...mapState(usePlanDesignStore, [
        'isPlanDesignValid',
        'planDesignIsPlan',
        'validPlanDesign',
        'loadingPlanDesign',
        'info',
        'values',
      ]),
      ...mapState(useRateEntryStore, [
        'isRateEntryValid',
        'rateAttributes',
        'validRateEntry',
      ]),
      /**
       * Evaluate the uploadRenewalUploadEnhancements feature flag.
       *
       * @returns {boolean}
       */
      uploadRenewalUploadEnhancementsEnabled() {
        return this.$ld.checkFlags(uploadRenewalUploadEnhancements);
      },

      /**
       * Error count text for stepper.
       *
       * @returns {string}
       */
      planDesignErrorCountText() {
        const stateCheck = ['not_started', 'in_progress_modifying', 'in_progress'];

        if (stateCheck.includes(this.currentProduct.state) || !this.isSmartProposal) {
          return '';
        }

        if (this.planDesignErrors?.length) {
          return `Missing ${this.planDesignErrors.length} of ${
            // filter out rate guarantee
            Object.values(this.values).filter(
              ({ plan_design_attribute_id: id }) => id !== this.currentProduct.rate_guarantee?.id,
            ).length
          } plan design values`;
        }

        if (this.uploadRenewalUploadEnhancementsEnabled) {
          return 'Plan design is complete';
        }

        return '';
      },

      /**
       * Show secondary Review Rates button
       *
       * @returns {boolean}
       */
      showReviewRatesSecondaryButton() {
        return this.isSmartProposal && !this.isPlanDesignValid && !this.isRateEntryValid;
      },

      /**
       * Show secondary Submit Quote button
       *
       * @returns {boolean}
       */
      showSubmitQuoteSecondaryButton() {
        return this.isSmartProposal && !this.isPlanDesignValid && this.isRateEntryValid;
      },

      /**
       * Used to enable the continue button if all plan design values not being patched or value is blank
       *
       * @returns {boolean}
       */
      canContinue() {
        // rate guarantee isn't added to value so no need to filter when it is plan based
        if (this.planDesignIsPlan) {
          return !Object.keys(this.values)
            .map((value) => this.values[value].value || null)
            .includes(null);
        }
        // we're filtering out Rate Guarantee since it is technically a value that exists inside the plan design values.
        // Rate Guarantee is filled out on the following Rate Entry page, therefore we don't want to let its null value
        // stop us from continuing into the next page since it will always be null at this step for any new quote.
        const financialCategory = this.info.categories.find(({ name }) => name === 'Financial');
        const rateGuaranteeAttributeId = financialCategory.plan_design_attributes.find(
          ({ name }) => name === 'Rate Guarantee',
        ).id;
        const planDesignValuesNoRateGuarantee = Object.entries(this.values)
          .filter(([key]) => this.values[key].plan_design_attribute_id !== rateGuaranteeAttributeId)
          .map(([, value]) => value);

        return !Object.keys(planDesignValuesNoRateGuarantee)
          .map((value) => planDesignValuesNoRateGuarantee[value].value || null)
          .includes(null);
      },
      /**
       * returns true if any product in completedStopLossProducts matches `productId`
       *
       * @returns {boolean}
       */
      hasCompletedStopLossProducts() {
        return this.completedStopLossProducts.some((product) => product.id !== this.productId);
      },

      /**
       * Determines if the user has visited this product.
       *
       * @returns {boolean}
       */
      hasVisitedProduct() {
        return this.planDesignProductsVisited.includes(this.productId);
      },

      /**
       * Determines if we let users have/use plan design preferences
       *
       * @returns {boolean}
       */
      allowUserPreferences() {
        const isRenewal = this.isRenewalProject || this.isRenewalProduct;

        return this.ffSaveUserDefaultPlanDesignValues && !this.$route.meta.readonly && !isRenewal;
      },
      /**
       * Evaluates the saveUserDefaultPlanDesignValues feature flag
       *
       * @returns {boolean}
       */
      ffSaveUserDefaultPlanDesignValues() {
        return this.$ld.checkFlags(saveUserDefaultPlanDesignValues);
      },
      /**
       * Evaluates the userDefaultsNotifications feature flag
       *
       * @returns {boolean}
       */
      ffUserDefaultsNotifications() {
        return this.$ld.checkFlags(userDefaultsNotifications);
      },
      /**
       * Allows the user to save the list of predefined values
       *
       * @returns {boolean}
       */
      canSavePredefinedValues() {
        return !!Object.keys(this.userPreferredPlanDesignValues).length;
      },
      /**
       * Will show finished button.
       *
       * @returns {boolean}
       */
      showFinishedButton() {
        const roleMatch = /threeflow_reviewer|admin/i.test(this.roleNames);

        return this.isSmartProposal && roleMatch && this.currentProduct.state === 'threeflow_assist';
      },
    },
    watch: {
      planDesignErrors() {
        this.currentError = this.currentErrorIndex();
      },
    },

    /**
     * get plan design and set destroyProductId
     */
    async mounted() {
      if (
        this.currentProduct.state === 'not_started'
        && this.productsWithPreferredPdvs.includes(this.currentProduct.product_type_id)
        && this.allowUserPreferences
      ) {
        await this.applyUserPreferredValues(this.productId);

        if (this.ffUserDefaultsNotifications) {
          this.$message({
            showClose: true,
            message:
              "We've applied your plan design preferences. Please review, refine, and confirm your entries before submission. You can edit and save over these values at any time.",
            type: 'warning',
          });
        }
      }

      await this.loadCallouts([this.productId], this.$ld.checkFlags(benefitCalloutComments));

      const isTfAssistAndDiveUnsupportedProduct = arrayHasItem(
        this.currentProduct.product_type_name, tfAssistAndDiveUnsupportedProductTypes,
      );
      const currentProductStatus = this.statusInfo(this.currentProduct, isTfAssistAndDiveUnsupportedProduct).status;

      if (
        this.isSmartProposal
        && isTfAssistAndDiveUnsupportedProduct
        && currentProductStatus === 'Not Started'
      ) {
        await this.applyUserPreferredValues(this.productId);
      }

      // destroyProductId needs to be set as the productId to be used in the beforeDestroy lifecycle.
      this.destroyProductId = this.productId;
    },
    created() {
      // Generate warning message
      if (this.currentProduct.state === 'completed' && !this.reviewAlertDismissed) {
        this.warningMessage = 'Making changes to your entries will remove your original quote from the broker’s view. Your quote will become visible to the broker again once you submit your updates.';
      } else if (!this.currentProductPreviouslySubmitted && !this.hasVisitedProduct) {
        // IF renewal product:
        // ELSE IF non-stop loss product alternative
        // ELSE base/alternative stop loss && takeover products)
        if (this.isRenewalProduct) {
          this.warningMessage = 'We have pre-populated your renewal quote based on your in-force policy.';
        } else if (
          this.isNewCoverage
          && this.currentProduct.project_product.alternative
          && !this.isStopLoss
        ) {
          this.warningMessage = 'This is a plan alternative. To get you started, we’ve built a plan design based on the broker’s request.';
        } else {
          this.warningMessage = `We’ve applied ${this.carrierName}’s standard language to the plan design.`;
        }

        if (this.isStopLoss && this.hasCompletedStopLossProducts) {
          this.warningMessage += ' Copy your previously submitted quote using the drop down to the right.';
        }

        this.warningMessage += ' Please review, refine, and confirm your entries before submission.';
      }

      // Auto Renewal warning takes precedence over all other warnings
      const isAutoRenew = this.autoRenewActivated;
      const isBaseProduct = !this.currentProduct.project_product.alternative;
      const isInProgress = !['not_started', 'completed', 'declined'].includes(
        this.currentProduct.state,
      );

      if (isAutoRenew && isBaseProduct && isInProgress) {
        this.warningMessage = 'Your renewal is in progress and will be ready to review within 2 business days of uploading files. If you need to replace your previous uploaded files, that will restart the 2 business day processing time.';
      }

      /**
       * Wait for rate errors to be populated, do some stuff, then destroy the watcher.
       * We only want this to fire once, on load, but not immediately. Only once the plan design errors are loaded.
       */
      const unwatch = this.$watch('planDesignErrors', () => {
        if (this.planDesignErrors.length > 0) {
          // Focus on the first error field
          this.nextError();
          document.querySelectorAll('input, textarea').forEach(
            (field) => {
              // When the user focuses a field we need to store which element in planDesignErrors the field is.
              field.addEventListener('focus', () => {
                this.currentError = this.currentErrorIndex();
              });
              // When the field is blurred, we need to wait a moment for the click handler to (potentially) run
              // then re-set the current error field index. This mostly handles setting the currentError value
              // back to 0 if the user clicks out of the field rather than clicking Next or Back
              field.addEventListener('blur', () => {
                this.fieldSwitchTimer = setTimeout(() => {
                  this.$nextTick(() => {
                    this.currentError = this.currentErrorIndex();
                  });
                }, 200);
              });
            },
          );
          unwatch();
        }
      });
    },
    /**
     * There is a timing issue where productId is destroyed before being used.
     * `destroyProductId` is used instead. destroyProductId needs to be set
     * as the productId to be used in the beforeDestroy lifecycle.
     */
    beforeDestroy() {
      // Validate the Plan Design on destroy instead of covering every page exit
      // Only do this when clicking on Submit or if they've filled out the form
      // and then left the page w/o clicking continue. This is sort of edge casey but want to cover it.
      if (this.canSubmit && !this.validPlanDesign) {
        this.validatePlanDesign(this.destroyProductId)
          // We are ignoring any error state here b/c we don't want to show anything
          // But we want to avoid uncaught promises
          .catch(() => {});
      }
      // Clear all the Plan Design things
      this.resetUserPreferredPdvs();
    },
    methods: {
      ...mapActions(usePlanDesignStore, [
        'getPlanDesign',
        'applyUserPreferredValues',
        'validatePlanDesign',
      ]),
      ...mapActions(useCallouts, ['loadCallouts']),
      ...mapActions(useUserPreferredPdvsStore, ['resetUserPreferredPdvs', 'savePreferredPlanDesignValues']),
      ...mapActions(useProductTableStore, ['statusInfo']),
      ...mapActions(useProductStore, [
        'validateProductAndSubmitQuote',
      ]),

      /**
       * Index of the currently focused error field
       *
       * @returns {number}
       */
      currentErrorIndex() {
        return this.planDesignErrors?.findIndex((field) => field().contains(document.activeElement));
      },
      /**
       * Advance focus to the next invalid field.
       */
      nextError() {
        // If the user clicked the next button we can kill the timeout
        // since we're going to set the value ourselves at the end of this method.
        clearTimeout(this.fieldSwitchTimer);
        if (this.planDesignErrors.length) {
          const index = this.currentError >= this.planDesignErrors.length - 1 ? 0 : this.currentError + 1;

          this.planDesignErrors[index]().focus();
        }
        // Save the current error field index
        this.currentError = this.currentErrorIndex();
      },
      /**
       * Validate Both Plan Design && Rate Entry before submitting the form
       */
      async handleSubmitQuote() {
        // disable the submit button to prevent double clicking
        this.validatingProduct = true;

        try {
          await this.validateProductAndSubmitQuote(this.productId);
        } catch {
          this.validateError();

          return;
        }

        const quoteOrRenewal = this.isSmartProposal ? 'Proposal' : 'Renewal';

        trackSegmentEvent(`Submit Smart ${quoteOrRenewal} from footer clicked`, {
          product_id: this.productId,
          product_type: this.currentProduct.product_type_name,
          carrier_name: this.carrierName,
          carrier_id: this.carrierId,
          project_id: this.projectId,
          proposal_id: this.proposalDocumentId,
        });

        this.$router.push({ name: 'RfpOverview' });
      },
      /**
       * Puts page in state to show error and activate Submit button on validation error for resubmit
       */
      validateError() {
        this.validatingProduct = false;
        this.$message({
          showClose: true,
          message: 'Your request did not go through. Please check the Rate Entry values for any errors.',
          type: 'error',
        });
      },

      /**
       * Sends your quotes analytics call.
       */
      sendYourQuotesAnalytics() {
        trackSegmentEvent(`Smart ${this.smartDocType(true)} back to Your Quotes`, { project_id: this.$route.params.projectId });
        this.$router.push({ name: 'RfpOverview' });
      },
      /**
       * sets `reviewAlertDismissed` to true if displayAlertReview is true.
       */
      onDismissAlert() {
        this.reviewAlertDismissed = true;
      },
      /**
       * Saves the users changed plan design values as their defaults
       * for future projects of the same type
       */
      async submitPreferredPlanDesignValues() {
        try {
          await this.savePreferredPlanDesignValues(
            this.productTypeId,
            this.userPreferredPlanDesignValues,
          );

          this.$message({
            showClose: true,
            message:
              'You’ve successfully saved your plan design preferences for this product. You can edit and save over these values at any time.',
            type: 'success',
          });
        } catch {
          this.$message({
            showClose: true,
            message: 'There was an error saving the plan design preferences. Please try again.',
            type: 'error',
          });
        }
      },
      /**
       * Save predefined values
       */
      savePreferences() {
        if (this.ffUserDefaultsNotifications) {
          this.toggleShowUserDefaultModal();
        } else {
          this.submitPreferredPlanDesignValues();
        }
      },
      /**
       * Toggles modal visibilty
       */
      toggleShowUserDefaultModal() {
        this.showUserDefaultModal = !this.showUserDefaultModal;
      },
    },
  };
</script>

<style lang="scss" scoped>
footer.renewal-footer {
  background: var(--tf-gray-dark);
  color: var(--tf-white);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  // space for Intercom icon.
  padding-right: 90px;

  > div {
    flex: 0;
  }

  .app-button {
    background: rgba(255, 255, 255, .2);
    border-color: var(--tf-white);
    border-width: 1px;
  }

  /**
   * TODO: This should be moved to shared. AppButton doesn't currently support icons on the left.
   */

  .icon-left {
    flex-direction: row-reverse;

    :deep(.button-text) {
      padding: 0 0 0 6px;
    }
  }

  .stepper-helptext {
    text-transform: uppercase;
    border-left: 1px solid var(--tf-white);
    border-right: 1px solid var(--tf-white);
    margin-left: 16px;
    margin-right: 16px;
    flex: 1;
  }

  .submit-button {
    border-color: #a3a5f9;
    background: rgba(163, 165, 249, .2);

    :deep(.app-icon) {
      color: #a3a5f9;
    }
  }
}
</style>
