<template>
  <div
    v-loading="loading && !rateUpdateRequests.length"
    class="rate-table"
  >
    <template v-if="tierGroup && !loading">
      <h5 v-if="!isPlanSummary && !rateAttributeIndex">
        <img
          class="logo"
          :src="incumbent
            ? currentProduct.project_product.inforce_product.carrier.logo_url
            : carrierLogoUrl
          "
          :alt="incumbent
            ? currentProduct.project_product.inforce_product.carrier.name
            : carrierName
          "
        >
      </h5>
      <ElForm
        ref="form"
        :model="formModel"
        :rules="formRules"
        :validate-on-rule-change="false"
        label-width="100px"
        @submit.native.prevent
      >
        <table :class="[{ stacked : isStacked }, 'table-data-entry']">
          <thead>
            <template v-if="isStacked">
              <tr>
                <!-- Only show the subtype cell for non-composite rate attribute type -->
                <th
                  v-if="attributeType !== compositeType"
                  class="title"
                  :data-test="`rate entry table ${attributeType} subtype`"
                  v-text="attributeType"
                />
                <th class="title">
                  <!-- Show subtype dropdown for !incumbent, !readonly -->
                  <!-- and tier groups with multiple subtypes -->
                  <ElSelect
                    v-if="!incumbent && !readonly && subtypeOptions.length"
                    v-model="stackedAttributeType"
                    placeholder="Select type"
                    data-test="select type dropdown"
                  >
                    <ElOption
                      :value="compositeType"
                      :label="$options.filters.tierGroupName(compositeType)"
                      :data-test="
                        `${$options.filters.tierGroupName(compositeType)?.toLowerCase() ?? 'composite'} option`
                      "
                    />
                    <ElOption
                      v-if="!isStopLoss"
                      :value="ageBandedType"
                      :label="$options.filters.tierGroupName(ageBandedType)"
                      :data-test="
                        `${$options.filters.tierGroupName(ageBandedType)?.toLowerCase() ?? 'ageBandedType'} option`
                      "
                    />
                    <ElOption
                      v-if="stackedAttributeType === customType"
                      :value="customType"
                      :label="$options.filters.tierGroupName(customType)"
                      :data-test="
                        `${$options.filters.tierGroupName(customType)?.toLowerCase() ?? 'customType'} option`
                      "
                    />
                  </ElSelect>
                  <template v-else>
                    {{
                      stackedAttributeType === ageBandedType
                        ? 'Age'
                        : stackedAttributeType | tierGroupName
                    }}
                  </template>
                </th>
                <th
                  :class="[{ 'proposed-title' : !incumbent }, 'title']"
                  v-text="incumbent ? 'In-Force rates' : 'Proposed rates'"
                />
              </tr>
            </template>
            <template v-else>
              <!-- Titles for Tier Groups that have multiple `subtypes` -->
              <tr v-if="tierSubtypes.length > 1">
                <th
                  v-for="subtype in tierSubtypes"
                  :key="subtype.name"
                  colspan="2"
                  class="sub-type-header"
                  :data-test="`rate entry table ${attributeType} subtype`"
                  v-text="subtype.name"
                />
              </tr>
              <!-- Titles for `subtypes`-->
              <tr>
                <!-- IF: Composite, Age Banded, and Custom -->
                <template v-if="[compositeType, ageBandedType, customType].includes(attributeType)">
                  <th
                    class="title"
                    :data-test="`rate entry table ${attributeType} subtype`"
                  >
                    {{ attributeType === ageBandedType ? 'Age' : attributeType | tierGroupName }}
                  </th>
                  <th :class="[{ 'proposed-title' : !incumbent }, 'title']">
                    {{ incumbent ? 'In-Force rates' : 'Proposed rates' }}
                  </th>
                </template>
                <!-- ELSE: need to loop through the subtypes  -->
                <template
                  v-for="(subtype, idx) in tierGroupInfo?.tier_subtypes"
                  v-else
                >
                  <th
                    :key="`${subtype.name}-${idx}-label`"
                    class="title"
                  >
                    <ElSelect
                      v-if="!incumbent
                        && !readonly
                        && ![compositeType, ageBandedType, customType].includes(attributeType)"
                      v-bind="{
                        value: subtypeOptions[idx]
                      }"
                      placeholder="Select type"
                      @change="updateSubtypeData($event, subtype.id)"
                    >
                      <ElOption
                        :value="compositeType"
                        :label="$options.filters.tierGroupName(compositeType)"
                        :data-test="
                          `${$options.filters.tierGroupName(compositeType)?.toLowerCase() ?? 'composite'} option`
                        "
                      />
                      <ElOption
                        v-if="!isStopLoss"
                        :value="ageBandedType"
                        :label="$options.filters.tierGroupName(ageBandedType)"
                        :data-test="
                          `${$options.filters.tierGroupName(ageBandedType)?.toLowerCase() ?? 'ageBandedType'} option`
                        "
                      />
                      <ElOption
                        v-if="(subtype.rate_values && subtype.rate_values[0].type === customType)
                          || (subtype.rate_value && subtype.rate_value.type === customType)"
                        :label="$options.filters.tierGroupName(customType)"
                        :value="customType"
                        :data-test="
                          `${$options.filters.tierGroupName(customType)?.toLowerCase() ?? 'customType'} option`
                        "
                      />
                    </ElSelect>
                    <template v-else>
                      <!-- If not age banded, print && filter subtype type -->
                      <template v-if="subtypeOptions[idx] !== ageBandedType">
                        {{ subtypeOptions[idx] | tierGroupName }}
                      </template>
                      <!-- Otherwise, print `Age` -->
                      <template v-else>
                        Age
                      </template>
                    </template>
                  </th>
                  <th
                    :key="`${subtype.name}-${idx}-value`"
                    :class="[{ 'proposed-title' : !incumbent }, 'title']"
                    v-text="incumbent ? 'In-Force rates' : 'Proposed rates'"
                  />
                </template>
              </tr>
            </template>
          </thead>
          <tbody v-if="tableRows.length">
            <template v-if="isStacked">
              <template v-for="subtype in tableRows">
                <tr
                  v-for="(value, idx) in subtype.rateValue?.values"
                  :key="`${subtype.name}-${value.display_label}`"
                >
                  <!-- First td in stacked only shows on non-composite and only the first row.  -->
                  <td
                    v-if="!idx && subtype.name !== compositeType"
                    :rowspan="subtype.rateValue?.values.length"
                    v-text="subtype.name"
                  />
                  <RateEntryTdLabel
                    :key="`${storeAttributeId}-${idx}-${value.label}`"
                    :label="value.display_label"
                    :subtype-type="stackedAttributeType"
                  />
                  <RateEntryTdValue
                    :cell-data="value"
                    :form-key="`${subtype.name}-${value.label}`"
                    :readonly="incumbent || readonly"
                    :subtype-id="subtype.id"
                    @updateTriggered="fieldUpdated"
                  />
                </tr>
              </template>
            </template>
            <template v-else>
              <tr
                v-for="(cellGroup, idx) in tableRows"
                :key="`${storeAttributeId}-${idx}`"
              >
                <!-- Each cellGroup is grouped in the table as 2 columns in the table -->
                <template v-for="(group, groupIdx) in cellGroup">
                  <RateEntryTdLabel
                    :key="`${storeAttributeId}-${groupIdx}-${group.label}`"
                    :label="group.display_label"
                    :subtype-type="subtypeOptions[groupIdx] || attributeType"
                  />
                  <RateEntryTdValue
                    :key="`${storeAttributeId}-${groupIdx}-value`"
                    :form-key="`${tierSubtypes[groupIdx].name}-${group.label}-${groupIdx}`"
                    :cell-data="group"
                    :readonly="readonly"
                    :subtype-id="tierSubtypes[groupIdx].id"
                    @updateTriggered="fieldUpdated"
                  />
                </template>
              </tr>
            </template>
          </tbody>
        </table>
      </ElForm>
    </template>
  </div>
</template>

<script>
  import { mapState, mapActions, mapWritableState } from 'pinia';
  import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
  import { useRateEntryStore } from '@/stores/rateEntry.js';
  import { useProductStore } from '@/stores/product.js';
  import { reusableAgeBandedValues as ageBanded } from '@watchtowerbenefits/es-utils-public';
  import { smartProposals, uploadRenewalUploadEnhancements } from '@/utils/featureFlags.js';
  import RateEntryTdLabel from './RateEntryTdLabel.vue';
  import RateEntryTdValue from './RateEntryTdValue.vue';

  const ageBandedType = 'AgeBandedRateValue';
  const compositeType = 'CompositeRateValue';
  const customType = 'CustomRateValue';

  /**
   * Rate Entry Table
   *
   * @exports RateEntry/RateEntryTable
   */
  export default {
    name: 'RateEntryTable',
    components: { RateEntryTdLabel, RateEntryTdValue },
    inject: [
      'storeAttributeId',
      'isPlanSummary',
      'isPlan',
    ],
    props: {
      /**
       * Rate Attribute type only required for the proposal document to determine table layout
       */
      attributeType: {
        type: String,
        default: compositeType,
      },
      /**
       * Boolean used to set readonly for incumbent table
       */
      incumbent: {
        type: Boolean,
        default: false,
      },
      /**
       * Number used to display if the carrier icons should show.
       */
      rateAttributeIndex: {
        type: Number,
        default: 0,
      },
      /**
       * Tier Group name + subtypes used to loop through for table rows
       */
      tierGroup: {
        type: Object,
        default: () => ({}),
      },
    },
    data: () => ({
      ageBandedType,
      compositeType,
      customType,
      loading: false,
      maxLength: 0,
      staleTimers: false,
      localFormModel: {},
    }),
    computed: {
      ...mapState(useRateEntryStore, ['rateAttributes', 'drainingUpdateRequests']),
      ...mapWritableState(useRateEntryStore, [
        'rateErrors',
        'rateUpdateRequests',
      ]),
      ...mapState(useCarrierInfoStore, {
        carrierLogoUrl: 'logoUrl',
        carrierName: 'name',
      }),
      ...mapState(useProductStore, [
        'isUploadRenewalRatePassOrSmartProposal',
        'rateTierGroups',
        'currentProduct',
        'isStopLoss',
        'productId',
        'productState',
      ]),
      /**
       * Age Banded array from shared repo for creating Age Banded Rows
       *
       * @returns {Array}
       */
      ageBanded,
      /**
       * Evaluate the smartProposals feature flag.
       *
       * @returns {boolean}
       */
      smartProposalEnabled() {
        return this.$ld.checkFlags(smartProposals);
      },
      /**
       * Evaluate the uploadRenewalUploadEnhancements feature flag.
       *
       * @returns {boolean}
       */
      uploadRenewalUploadEnhancementsEnabled() {
        return this.$ld.checkFlags(uploadRenewalUploadEnhancements);
      },
      /**
       * Boolean to determine layout
       *
       * @returns {boolean}
       */
      isComposite() {
        return this.attributeType === compositeType;
      },
      /**
       * Boolean to determine the state of the tables (review or edit)
       *
       * @returns {boolean}
       */
      readonly() {
        const reviewingProposal = !this.incumbent && this.$route.meta.readonly;

        return this.incumbent || reviewingProposal;
      },
      isStacked() {
        return this.tierGroupInfo.layout === 'stacked';
      },
      stackedAttributeType: {
        /**
         * Attribute type of a stacked tier group layout.
         * For non-incumbents, you can grab any rate value id id since all the attribute types should all be the same.
         * non-stacked layouts do not need this info.
         *
         * @returns {string}
         */
        get() {
          let attributeType;

          // if product is stopLoss only Composite is allowed
          if (this.attributeType === compositeType || this.isStopLoss) {
            return compositeType;
          }

          if (this.incumbent) {
            const subtype = this.tierGroup.tier_subtypes[0];

            if (this.isPlan) {
              attributeType = subtype.rate_value.type;
            } else {
              attributeType = subtype.rate_values[0].values[0].type;
            }
          } else {
            const rateAttribute = this.rateAttributes[this.storeAttributeId];
            const subtypeId = this.tierGroupInfo.tier_subtypes[0].id;

            attributeType = rateAttribute.rateValues[subtypeId]?.type;
          }

          return attributeType;
        },
        /**
         * creates stacked attribute types
         *
         * @param {string} value
         */
        async set(value) {
          this.tierGroupInfo.tier_subtypes.forEach((subtype) => this.updateRateAttribute({
            productId: this.productId,
            storeAttributeId: this.storeAttributeId,
            subtypeId: subtype.id,
            rateValues: {
              [subtype.id]: {
                type: value,
                values: value === compositeType
                  ? [this.addCompositeRow()]
                  : this.ageBanded.map((age, i) => this.addAgeBandedRow(i)),
              },
            },
          }));

          this.rateErrors.splice(0, this.rateErrors.length);
        },
      },
      /**
       * Form model for validation against
       *
       * @returns {object}
       */
      formModel() {
        return Object.fromEntries(
          this.tableRows.flatMap(
            (row) => {
              if (Array.isArray(row)) {
                const formFields = [];

                this.tierSubtypes.forEach(({ id, name }) => {
                  formFields.push(...row.map(({ label, value }, i) => {
                    const formKey = `${name}-${label}-${i}`;

                    return [
                      formKey,
                      this.localFormModel[formKey]
                        || !Number.isNaN(value)
                        ? value
                        : this.rateAttributes[this.storeAttributeId].rateValues[id].values
                          .find(({ label: attributeLabel }) => label === attributeLabel)?.value,
                    ];
                  }));
                });

                return formFields;
              }

              return row.rateValue.values.map(({ value, label }) => {
                const formKey = `${row.name}-${label}`;

                return [formKey, this.localFormModel[formKey] || value];
              });
            },
          ),
        );
      },
      /**
       * Form validation rules
       *
       * @returns {object}
       */
      formRules() {
        const formRules = {};

        Object.keys(this.formModel).forEach((key) => {
          formRules[key] = [{
            required: true,
            message: 'Rates must be numerical',
            validator: (rule, value, callback) => {
              if (!Number.isNaN(parseFloat(value))) {
                callback();
              } else {
                callback(true);
              }
            },
          }];
        });

        return formRules;
      },
      /**
       * Computed tier subtypes
       *
       * @returns {Array}
       */
      tierSubtypes() {
        const tierSubtypes = [];

        if (!this.isComposite) {
          this.tierGroup.tier_subtypes.forEach((subtype) => {
            // Push subtype option to this.subtypeOptions (only if there are subtype)
            if (this.tierGroup.tier_subtypes.length > 1) {
              tierSubtypes.push({
                id: subtype.id,
                name: subtype.name,
              });
            } else {
              tierSubtypes.push({
                id: this.attributeType,
                name: this.attributeType,
              });
            }
          });
        } else {
          tierSubtypes.push({
            id: compositeType,
            name: compositeType,
          });
        }

        return tierSubtypes;
      },
      /**
       * Populates the subtype options select
       *
       * @returns {Array}
       */
      subtypeOptions() {
        const subtypeOptions = [];

        if (!this.isComposite) {
          this.tierGroup.tier_subtypes.forEach((subtype) => {
            let subtypeValue;

            if (this.isPlan) {
              subtypeValue = subtype.rate_value;
            } else {
              [subtypeValue] = subtype.rate_values;
            }

            if (this.tierGroup.tier_subtypes.length > 1) {
              // Stack options only need one the subtype option
              const existingOption = this.isStacked
                && !subtypeOptions.includes(subtypeValue.type);

              // Push options if not stacked or stacked && !exists
              if (!this.isStacked || existingOption) {
                subtypeOptions.push(subtypeValue.type);
              }
            }
          });
        }

        return subtypeOptions;
      },
      /**
       * Computed subtype data for 'banded' tier groups
       *
       * @returns {object}
       */
      tierGroupInfo() {
        let tierGroupInfo = {};

        if (![ageBandedType, compositeType, customType].includes(this.attributeType)) {
          const updatedTierGroup = this.rateTierGroups
            .find((tierGroup) => tierGroup.tier_group_name === this.attributeType);

          if (updatedTierGroup) {
            const {
              tier_group_id: id,
              tier_group_layout: layout,
              tier_group_name: name,
              tier_subtypes: subtypes,
            } = updatedTierGroup;

            tierGroupInfo = {
              id,
              layout,
              name,
              /* eslint-disable no-shadow */
              tier_subtypes: subtypes.map(({
                subtype_id: id,
                subtype_name: name,
              }) => ({
                id,
                name,
              })),
            };
          }
        }

        return tierGroupInfo;
      },
      /**
       * Computed table rows
       *
       * @returns {Array}
       */
      tableRows() {
        const tableRows = [];
        const rowValues = []; // Used to create each cell grouping in the row
        let values = [];
        let maxLength = 0;

        if (this.isComposite) {
          if (this.isStacked) {
            tableRows.push({
              id: compositeType,
              name: compositeType,
              rateValueType: compositeType,
              rateValue: this.tierGroup.tier_subtypes[0].rate_value,
            });
          } else {
            tableRows.push([
              this.isPlan
                ? this.tierGroup.tier_subtypes[0].rate_value.values[0]
                : this.tierGroup.tier_subtypes[0].rate_values[0].values[0],
            ]);
          }
        } else {
          this.tierGroup.tier_subtypes.forEach((subtype, idx) => {
            let id;

            if (this.isPlan) {
              id = subtype.rate_value.id;
              values = subtype.rate_value.values;
            } else {
              id = subtype.rate_values[0].id;
              values = subtype.rate_values[0].values;
            }

            // IF: add all values for each `tier subtype`
            // ELSE: replace maxLength of table rows if greater than previous `tier subtype`
            if (this.isStacked) {
              maxLength += values.length;
            } else {
              maxLength = values.length > maxLength
                ? values.length
                : maxLength;
            }

            // IF: Push modified subtype object to tableRows
            // ELSE: Store values of each subtype to loop through later
            if (this.isStacked) {
              tableRows.push({
                id: subtype.id,
                name: subtype.name,
                rateValue: this.isPlan
                  ? subtype.rate_value
                  : subtype.rate_values[0],
              });
            } else {
              rowValues.push({ id: (id || idx), values });
            }
          });

          if (!this.isStacked) {
            for (let i = 0; i <= maxLength - 1; i += 1) {
              const rows = [];

              // Loop through each subtype
              rowValues.forEach((rowSubtype, index) => {
                rows[index] = rowSubtype.values[i] || {};
              });
              tableRows.push(rows);
            }
          }
        }

        return tableRows;
      },
    },
    watch: {
      /**
       * Update rateErrors with invalid fields
       */
      rateErrors() {
        this.$nextTick(this.getInvalidFields);
      },
    },
    /**
     * create rows for attribute table when component is created
     */
    async created() {
      this.$nextTick(this.getInvalidFields);
    },
    methods: {
      ...mapActions(useRateEntryStore, [
        'updateRateAttribute',
        'getRateEntry',
        'drainUpdateRequests',
      ]),
      /**
       * Child component has updated a form value, save it for validation purposes until the request is finished
       *
       * @param {string} formKey
       * @param {string} value
       */
      fieldUpdated(formKey, value) {
        if (value || value === '') {
          this.localFormModel[formKey] = value;
        } else {
          this.$delete(this.localFormModel, formKey);
        }
      },
      /**
       * Configure the rate entry ElForm instance to use validation.
       * Populate rateErrors in the store with a list of component refs
       */
      getInvalidFields() {
        // rff: uploadRenewalUploadEnhancements
        if (!this.uploadRenewalUploadEnhancementsEnabled) {
          return;
        }

        /**
         * Populate/sync rateErrors with the list of ElFormItem elements with validation errors
         */
        this.$refs.form.validate((valid, invalid) => {
          const existingErrorFields = this.rateErrors.map((field) => field());
          /**
           * After we validate the form we refresh rateError with invalid fields without wiping out items from other forms
           */
          const fields = [...this.$refs.form.fields];
          const fieldOrder = this.tableRows.flatMap(
            (row) => {
              if (Array.isArray(row)) {
                const formFields = [];

                this.tierSubtypes.forEach(({ name }) => {
                  formFields.push(...row.map(({ label }, i) => `${name}-${label}-${i}`));
                });

                return formFields;
              }

              return row.rateValue.values.map(({ label }) => `${row.name}-${label}`);
            },
          );

          fields.sort((a, b) => {
            if (fieldOrder.indexOf(a.prop) > fieldOrder.indexOf(b.prop)) {
              return 1;
            }
            if (fieldOrder.indexOf(a.prop) < fieldOrder.indexOf(b.prop)) {
              return -1;
            }

            return 0;
          });

          const invalidFields = fields.filter(
            ({ prop }) => Object.keys(invalid).includes(prop) && !this.localFormModel[prop],
          ).map(({ $el }) => $el.querySelector('input, textarea'));
          const formFields = this.$refs.form.fields.map(({ $el }) => $el.querySelector('input, textarea'));

          if (!invalidFields.every((field) => existingErrorFields.includes(field))) {
            const updatedErrors = this.rateErrors.filter((field) => !formFields.includes(field()));

            this.rateErrors.splice(
              0,
              this.rateErrors.length,
              ...updatedErrors,
              ...invalidFields.map((field) => () => field),
            );
          }
        });
      },
      /**
       * Method used to add a composite cell group.
       *
       * @returns {object}
       */
      addCompositeRow() {
        return {
          comparison_flag: 'deviation_detected',
          display_label: 'Composite',
          label: 'composite',
          value: null,
          volume: null,
        };
      },
      /**
       * Method used to add a age banded cell group
       * Pass the `idx` to create the appropriate age label.
       *
       * @param {number} idx
       * @returns {object}
       */
      addAgeBandedRow(idx) {
        return {
          comparison_flag: 'deviation_detected',
          display_label: this.ageBanded[idx] === 'age_80_plus'
            ? '80+'
            : this.ageBanded[idx].replace('age_', '').replace('_', '-'),
          label: this.ageBanded[idx],
          value: null,
          volume: null,
        };
      },
      /**
       * Method used when updating the subtier type from the table header dropdown.
       *
       * @param {string} rateValueType
       * @param {number} id
       */
      updateSubtypeData(rateValueType, id) {
        const maxRowLength = this.subtypeOptions.includes(ageBandedType)
          ? this.ageBanded.length
          : 1;
        const values = [];
        let i = 0;
        let subtypeObj;

        for (i; i < maxRowLength; i += 1) {
          if (rateValueType === compositeType) {
            // Update 0 array cellGroupIdx to composite && empty/{} for everything else in the cells
            subtypeObj = !i ? this.addCompositeRow() : {};
          } else {
            subtypeObj = this.addAgeBandedRow(i);
          }

          // The case for this is if you have a combination of composite/age-banded rows,
          // We're pushing an empty object to the row (for layout)
          // But we don't want to push empty objects to the store.
          if (Object.keys(subtypeObj).length) {
            values.push(subtypeObj);
          }
        }

        this.updateAttributes({
          productId: this.productId,
          storeAttributeId: this.storeAttributeId,
          tierGroupId: this.tierGroup.id,
          subtypeId: id,
          rateValues: {
            [id]: {
              type: rateValueType,
              values,
            },
          },
        });
      },
      /**
       * Debounce the patch calls made to the API so that changes made to multiple inputs
       * in quick succession don't get fired to the API every time. We only need the latest
       * change made in a given value column to be sent to the API.
       *
       * @param {object} newAttributes
       */
      async updateAttributes(newAttributes) {
        this.rateUpdateRequests.push(((payload) => async (rateAttributes) => {
          // Do the patch
          const newRateAttributes = await this.updateRateAttribute({
            ...payload,
          }, rateAttributes);

          // and pass it to (potentially) the next update event IIFE
          return newRateAttributes[payload.storeAttributeId];
        })({
          ...newAttributes,
        }));

        this.drainUpdateRequests();
      },
    },
  };
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  margin-bottom: 20px;
}

.logo {
  display: block;
  height: 60px;
  margin: 0 auto;
}

.rate-table {
  flex: 1;
  flex-direction: column;

  &:first-child {
    margin-right: 20px;

    .dialog-plan-summary & {
      margin-right: 0;
    }
  }
}

.title {
  padding: {
    left: 6px;
    right: 6px;
  }
  background: $tf-extra-light-gray-2;

  .stacked & {
    text-align: left;
    padding: 0 20px;
    white-space: nowrap;
  }
}

.proposed-title {
  font-weight: bold;
}

.el-alert {
  margin-bottom: 20px;
}

tbody, th {
  border: 1px solid $tf-extra-light-gray-1;
}

h5 {
  display: flex;
  align-items: center;
  height: 80px;
  margin-bottom: 0;
  border: 1px solid $tf-extra-light-gray-1;
}

td {
  vertical-align: top;
}
</style>
