<template>
  <span
    class="state-icon-container"
    data-test="product state info"
  >
    {{ stateInfo.iconText }}
    <span
      :class="[
        'state-icon',
        stateInfo.iconType,
        stateInfo.iconClass,
        { requested: stateInfo.iconType === 'warning' && stateInfo.iconClass === 'requested' },
      ]"
    >
      <AppIcon
        :size="isRow ? stateInfo.iconSizeRow : stateInfo.iconSize"
        :icon="stateInfo.icon"
        :type="stateInfo.iconType"
        :class="stateInfo.iconClass"
        class="product-state-info-icon"
      />
    </span>
  </span>
</template>

<script>
/**
 * Product State Info
 *
 * @exports Product/ProductStateInfo
 */
  export default {
    name: 'ProductStateInfo',
    props: {
      isRow: {
        type: Boolean,
        default: false,
      },
      stateInfo: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
  };
</script>

<style lang="scss" scoped>
  .state-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    margin-left: 10px;
    color: $tf-light-gray;
    border: 2px solid $tf-light-gray;
    border-radius: 100%;

    :deep(.fa-xl) {
      line-height: inherit;
    }

    &.danger {
      color: var(--tf-danger);
      border-color: var(--tf-danger);
    }

    &.success {
      color: var(--tf-success);
      border-color: var(--tf-success);
    }

    &.warning {
      color: var(--tf-warning);
      border-color: var(--tf-warning);

      &:not(.requested) {
        border: {
          bottom-color: $tf-light-gray;
          left-color: $tf-light-gray;
        }
        /* Rotating since the border is only 1/2 */
        transform: rotate(45deg);

        /* Then rotate the icon back */
        .product-state-info-icon {
          transform: rotate(-45deg);
        }
      }
    }

    &.selected {
      border: none;
    }

    .card-row & {
      width: 20px;
      height: 20px;
      margin-left: 0;
      margin-right: 10px;
    }
  }

  .state-icon-container {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;

    .card-row & {
      flex-direction: row-reverse;
    }
  }
</style>
