<template>
  <div v-loading="!isLoaded">
    <TfTable
      v-if="isLoaded"
      :class="{'show-filter': ffProductTypesColumn}"
      v-bind="{
        filterValues: $route.params.filters,
        pageSelected: Number($route.params.pageSelected),
        pageLimit: 20,
        truncatedPageRange,
        pagination: true,
        parentalControls: true,
        customSort,
        tableData,
        tableMeta,
      }"
      show-page-results
      @changeFilter="changeFilter"
      @pageSelected="changeRouteParams({ pageSelected: $event })"
      @changeSortCriteria="changeRouteParams({
        sortDirection: $event.sortDirection,
        sortProp: $event.sortProp
      })"
    >
      <DashboardRow
        slot="parentRow"
        slot-scope="{ rowData }"
        :row-data="rowData"
      />
      <template slot="empty">
        There are no opportunities to display.
      </template>
      <template
        v-if="errorMessage"
        #error
      >
        {{ errorMessage }}
      </template>
    </TfTable>
  </div>
</template>

<script>
  // Utils
  import { mapState } from 'pinia';
  import { ReusableData } from '@watchtowerbenefits/shared-components';
  import {
    cp765TablePagination,
    statusFilterReorder,
    customTableSort,
    productTypesColumn,
  } from '@/utils/featureFlags.js';
  import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
  import { segmentData } from '@/utils/analytics.js';
  import { useMultiSort } from '@/utils/customSorts.js';
  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
  // Services
  import ServiceProject from '@/services/project.js';
  // dayjs
  import dayjs from 'dayjs';
  import isSameOrAfter from 'dayjs/plugin/isSameOrAfter.js';

  // Components
  import DashboardRow from './DashboardRow.vue';

  // Use DayJs Plugin
  dayjs.extend(isSameOrAfter);

  // Instead of sorting the status prop alphabetically, we want
  // to sort by a specific order listed below
  const oldStatusSortOrderMap = {
    Incomplete: 1,
    New: 2,
    Sold: 3,
    Submitted: 4,
    Declined: 5,
  };
  const statusSortOrderMap = {
    New: 1,
    Incomplete: 2,
    'New (past due)': 3,
    'Incomplete (past due)': 4,
    Submitted: 5,
    Sold: 6,
    Declined: 7,
  };

  /**
   * A component that uses tfTable to display all our proposal documents in various states.
   *
   * @exports Dashboard/DashboardTable
   */
  export default {
    name: 'DashboardTable',
    components: { DashboardRow },
    props: {
      dashboardType: {
        type: String,
        required: true,
      },
      dataReturnKey: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
    },
    data: () => ({
      errorMessage: '',
      isLoaded: false,
      tableData: [],
      projectTypes: ReusableData.projectTypes,
      usedStatuses: [],
      usedProductTypes: [],
    }),
    computed: {
      ...mapState(useCarrierInfoStore, { carrier_id: 'id' }),
      /**
       * Columns for tableMeta using statusFilter for custom ordered status filter.
       *
       * @returns {Array}
       */
      columns() {
        return [
          {
            format: 'ctas',
            label: 'Event type',
            prop: 'eventType',
            sortable: true,
            filter: {
              type: 'string',
              width: '12%',
            },
            width: '10%',
          },
          {
            label: 'Broker name',
            prop: 'broker_name',
            sortable: true,
            filter: {
              type: 'string',
              width: '16%',
            },
            width: '15%',
          },
          {
            label: 'Employer name',
            prop: 'employer_name',
            sortable: true,
            filter: {
              type: 'string',
              width: '16%',
            },
            width: '23%',
          },
          {
            hidden: true,
            format: 'withTooltip',
            label: 'Products',
            prop: 'product_types',
            filter: this.productTypesFilter,
            width: '10%',
          },
          {
            format: 'date',
            label: 'Effective date',
            prop: 'effective_date',
            sortable: true,
            filter: {
              type: 'month-and-date-range',
              width: '12%',
            },
            width: '12%',
          },
          {
            format: 'withClass',
            label: 'Status',
            prop: 'status',
            sortable: 'statusSortOrder,due_date',
            filter: this.statusFilter,
            width: '8%',
          },
          {
            format: 'date',
            label: 'Due date',
            prop: 'due_date',
            sortable: true,
            filter: {
              type: 'date-range-input',
              width: '13%',
            },
            width: '7%',
          },
          {
            format: 'number',
            label: 'Employee count',
            prop: 'employer_total_lives',
            sortable: true,
            filter: {
              options: [
                {
                  label: 'Less than 100',
                  value: '0,99',
                },
                {
                  label: '100 - 499',
                  value: '100,499',
                },
                {
                  label: '500 - 999',
                  value: '500,999',
                },
                {
                  label: '1,000 +',
                  value: '1000,null',
                },
              ],
              type: 'range',
              width: '10%',
            },
            width: '15%',
          },
        ];
      },
      /**
       * Evaluates the statusFilterReorder feature flag
       *
       * @returns {boolean}
       */
      ffStatusFilterReorder() {
        return this.$ld.checkFlags(statusFilterReorder);
      },
      /**
       * customTableSort feature flag
       *
       * @returns {boolean}
       */
      ffCustomTableSort() {
        return this.$ld?.checkFlags(customTableSort);
      },
      /**
       * product types column feature flag
       *
       * @returns {boolean}
       */
      ffProductTypesColumn() {
        return this.$ld?.checkFlags(productTypesColumn);
      },
      /**
       * Builds filter list for custom ordered status filter on All opportunites page.
       *
       * @returns {Array}
       */
      statusFilter() {
        const filter = { type: 'string', width: '11%' };

        if (this.ffStatusFilterReorder) {
          filter.options = Object.keys(statusSortOrderMap)
            .filter((status) => this.usedStatuses.includes(status))
            .map((status) => ({
              label: status,
              value: status,
            }));
        }

        return filter;
      },
      /**
       * Builds filter list for product types.
       *
       * @returns {Array}
       */
      productTypesFilter() {
        const filter = { type: 'array', width: '11%' };

        filter.options = [...this.usedProductTypes]
          .sort((a, b) => a.localeCompare(b))
          .map((key) => (
            {
              label: key,
              value: key,
            }
          ));

        return filter;
      },
      /**
       * custom sort for tf-table, should only be used when customTableSort feature flag is enabled.
       *
       * @returns {Function|null}
       */
      customSort() {
        return this.ffCustomTableSort ? this.multiKeySort : null;
      },
      /**
       * Combines this.column with the route params for the sorting.
       *
       * @returns {object}
       */
      tableMeta() {
        return {
          columns: this.columns,
          emptyValue: '&mdash;',
          sort: {
            prop: this.$route.params.sortProp,
            direction: this.$route.params.sortDirection,
          },
        };
      },
      /**
       * feature flag for pagination
       *
       * @returns {number}
       */
      ffCp765TablePagination() {
        return this.$ld.checkFlags(cp765TablePagination);
      },
      /**
       * Check ff and then returns the truncatedPageRange or 0.
       *
       * @returns {number}
       */
      truncatedPageRange() {
        if (this.ffCp765TablePagination) {
          return 5;
        }

        return 0;
      },
    },
    /**
     * will run the getProjects service and format data for this table
     */
    created() {
      this.getTableData();
    },
    methods: {
      /**
       * Pushes the passed param to the router.
       * If there are no filters passed, we need to clone the params
       * and push to the same named route to remove the filter param.
       *
       * @param {object} filters
       */
      changeFilter(filters) {
        let route = {
          params: { filters },
        };

        if (!filters) {
          const params = { ...this.$route.params };

          delete params.filters;

          route = {
            name: this.$route.name,
            params,
          };
        }

        if (filters) this.trackRequest(JSON.parse(filters));
        this.$router.push(route);
      },
      /**
       * Push the passed param to the router.
       *
       * @param {object} params
       */
      changeRouteParams(params) {
        this.$router.push({ params });
      },
      /**
       * Get the table data based on the dashboardType that is passed as a prop.
       */
      getTableData() {
        const inProgressSteps = [
          'in_progress',
          'in_progress_pending_confirmation',
          'in_progress_modifying',
          'editing',
          'editing_plan_design',
          'pending_validation',
          'automation_locked',
          'action_needed',
        ];

        ServiceProject.getProjects(this.dashboardType)
          .then((data) => {
            this.tableData = data[this.dataReturnKey].reduce((accRows, {
              id,
              type,
              document: { products, state: documentState },
              product_types: productTypes,
              broker_name: brokerName,
              due_date: dueDate,
              effective_date: effectiveDate,
              employer_name: employerName,
              employer_total_lives: employerTotalLives,
            }) => {
              // We want to prevent the user from accessing RFPs w/o a due date.
              if (!dueDate) {
                return accRows;
              }

              let pastDue = false;
              let status;
              // We want to determine the Event Type based on the project type for each row (RLH 3/31/21)
              const eventType = type === this.projectTypes.renewal
                ? 'Renewal collection'
                : 'Full marketing';
              // possible product states
              const productsSold = products.some(({ is_sold: isSold }) => isSold);
              const productsNotStarted = products.some(({ state }) => state === 'not_started');
              const productsInProgress = products
                .some(({ state }) => inProgressSteps.includes(state));
              const productsCompletedOrDeclined = products.some(({ state }) => (
                ['completed', 'declined'].includes(state)
              ));

              // orderStatus refers to the sort order for the status prop for each row.
              // We want to sort these statuses by a specific order rather than alphabetically
              // the order is as follows: Incomplete 1, New 2, Sold 3, Submitted 4, Declined 5, [other] 6
              switch (documentState) {
              case 'data_entry': // 'data_entry' can returned if the carrier has downloaded the in-force packet.
              case 'not_started':
                status = 'New';
                // We want to see if any products are in progress or have been completed/declined to update to print `Incomplete`
                if (productsInProgress || productsCompletedOrDeclined) {
                  status = 'Incomplete';
                }
                break;
              case 'needs_review':
              case 'reviewed':
                status = 'Incomplete';
                break;
              case 'finalized':
                // A quote can be finalized but we want to check for if a carrier comes back to edit a submitted/declined a product.
                if (productsInProgress || productsNotStarted) {
                  status = 'Incomplete';
                } else if (productsSold) {
                  status = 'Sold';
                } else {
                  status = 'Submitted';
                }
                break;
              case 'declined':
                status = 'Declined';
                break;
              default:
                status = documentState;
              }
              // assign numerical sort order for status using map
              // if a status isn't recognized, default to last place in sort order.
              let statusSortOrder = oldStatusSortOrderMap[status] || 6;

              if (['Incomplete', 'New'].includes(status)) {
                pastDue = dayjs().isSameOrAfter(dayjs(dueDate));

                if (pastDue) {
                  status += ' (past due)';
                }
              }

              // if item not in array, add it
              const pushUnique = (item, arr) => {
                if (!arr.includes(item)) arr.push(item);
              };

              // reassign status sort order for FF
              if (this.ffStatusFilterReorder) {
                statusSortOrder = statusSortOrderMap[status] || 1;
                // build usesStatus array for custom ordered status filter
                pushUnique(status, this.usedStatuses);
              }

              // We want to display only unique product type names
              const uniqueProductTypes = [...new Set(productTypes.map(({ name }) => name))];

              uniqueProductTypes.forEach((prodType) => {
                pushUnique(prodType, this.usedProductTypes);
              });

              return accRows.concat({
                id,
                eventType,
                broker_name: brokerName,
                due_date: dueDate,
                effective_date: effectiveDate,
                employer_name: employerName,
                employer_total_lives: employerTotalLives,
                product_types: uniqueProductTypes,
                statusSortOrder,
                pastDue,
                status,
              });
            }, []);
          })
          .catch((error) => {
            this.errorMessage = `Error loading projects: ${error}`;
          })
          .finally(() => {
            this.isLoaded = true;
          });
      },
      /**
       * A custom sort function used for the opportunities table. This is used to sort
       * the status column by both the status primarily and the due date secondarily.
       *
       * @param {Array} arrayToSort - the array to sort
       * @param {string} sortKeys - a comma separated string of keys to sort by
       * @param {string}  sortDirection - the direction to sort by
       * @returns {Array} a new array of the sorted contents
       */
      multiKeySort(
        arrayToSort,
        sortKeys = '',
        sortDirection = 'asc',
      ) {
        return [...arrayToSort].sort(useMultiSort(sortDirection, ...sortKeys.split(',')));
      },
      /**
       * Sends analytics of filters changed.
       *
       * @param {object} filters
       */
      trackRequest(filters) {
        const filterMap = {
          eventType: 'event_type',
          broker_name: 'broker_name',
          employer_name: 'employer_name',
          effective_date: 'effective_date',
          status: 'status',
          product_types: 'product_types',
          due_date: 'due_date',
          employer_total_lives: 'employee_count',
        };
        const filterNames = Object.keys(filters);
        let activeFilter;
        const paramFilters = this.$route.params.filters
          ? JSON.parse(this.$route.params.filters)
          : null;

        if (paramFilters) {
          filterNames.forEach((filter) => {
            if ((JSON.stringify(paramFilters[filter]) !== JSON.stringify(filters[filter]))) {
              activeFilter = filter;
            }
          });
        } else {
          [activeFilter] = filterNames;
        }

        trackSegmentEvent(`${filterMap[activeFilter]}_filter_changed`, segmentData({
          carrier_id: this.carrier_id,
        }));
      },
    },
  };
</script>
<style lang="scss" scoped>
.show-filter {
  :deep(.filter-container > :nth-child(4)) {
    display: block;
  }
}

// hides product column
:deep(.table tr > *:nth-child(4)) {
  display: none;
}

// hides product column header
:deep(.table thead tr th:nth-child(4)) {
  display: none;
}

// hides product filter
:deep(.filter-container > :nth-child(4)) {
  display: none;
}
</style>
