<template>
  <ElDialog
    :visible="visible"
    :before-close="onBeforeClose"
    top="70px"
    width="450px"
  >
    <img
      :src="declineIcon"
      alt="Decline icon"
    >
    <p data-test="decline confirmation text">
      <template v-if="productsToDecline?.length === 1 && !decliningAllProducts">
        Are you sure you want to decline to quote for
        <template v-if="isStopLossProduct(productsToDecline[0])">
          this stop loss option?
        </template>
        <template v-else>
          this {{ lowercaseExceptAcronyms(productsToDecline[0].product_type_name) }}
          <template v-if="productsToDecline[0].project_product.alternative">
            &ndash; {{ lowercaseExceptAcronyms(productsToDecline[0].project_product.label) }}
          </template>
          product?
        </template>
      </template>
      <template v-else-if="decliningMultipleProducts && !decliningAllProducts">
        Are you sure you want to decline to quote for these products?
      </template>
      <template v-else>
        Are you sure you want to decline to quote on all products?
      </template>
    </p>

    <p id="decline-modal-info">
      This reason will be sent to the broker 10 minutes after declining.
      You can undo declined to quote products in the Your quotes section.
    </p>
    <p
      id="reason-for-declining-label"
      class="radio"
    >
      Select a reason for declining:
    </p>
    <div
      id="decline-reason-options"
      data-test="reasons for declining"
    >
      <ElRadio
        v-for="option of predefinedOptions"
        :key="option"
        v-model="predefinedReason"
        :label="option"
        :data-test="`select ${option.toLowerCase()} reason`"
      >
        {{ option === 'Other' ? 'Other reason' : option }}
      </ElRadio>
    </div>
    <LimitedTextArea
      v-if="predefinedReason === 'Other'"
      id="reason-for-declining"
      v-model="userDefinedReason"
      data-test="decline reason"
      :limit-exceeded.sync="limitExceeded"
    />
    <div class="btn-group">
      <AppButton
        :is-disabled="btnDeclining"
        data-test="cancel decline quote"
        size="text"
        text="Cancel"
        @click="closeDeclineToQuoteDialog"
      />
      <AppButton
        :is-disabled="!canDecline"
        :is-loading="btnDeclining"
        :text="declineButtonText"
        class="confirm-decline-btn"
        data-test="decline to quote"
        size="medium"
        type="decline"
        @click="declineAction"
      />
    </div>
  </ElDialog>
</template>

<script>
  import project from '@/mixins/project.js';
  import { mapActions, mapWritableState, mapState } from 'pinia';
  import { useNotificationsStore } from '@/stores/notifications.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductStore } from '@/stores/product.js';
  import DocumentServices from '@/services/documents.js';
  import { lowercaseExceptAcronyms } from '@/utils/regex.js';
  import { segmentData } from '@/utils/analytics.js';
  import { isStopLossProduct, trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
  import declineIcon from '@/assets/decline.svg';
  import { uploadRenewalUploadEnhancements } from '@/utils/featureFlags.js';
  import LimitedTextArea from '../LimitedTextArea.vue';

  /**
   * Decline Product
   *
   * @exports Modals/DeclineToQuoteModal
   */
  export default {
    name: 'DeclineToQuoteModal',
    components: { LimitedTextArea },
    mixins: [project],
    props: {
      productsToDecline: {
        type: Array,
        default: () => [],
      },
      visible: {
        type: Boolean,
        default: false,
      },
      callback: {
        type: Function,
        default: () => {},
      },
    },
    data: () => ({
      btnDeclining: false,
      declineIcon,
      userDefinedReason: '',
      predefinedReason: '',
      limitExceeded: false,
      lowercaseExceptAcronyms,
    }),
    computed: {
      ...mapWritableState(useNotificationsStore, ['allProductsSubmittedDialogVisible']),
      ...mapWritableState(useProductStore, { storeProductsToDecline: 'productsToDecline' }),
      ...mapState(useProjectStore, ['proposalDocumentId']),
      ...mapState(useProductStore, [
        'isUploadRenewalRatePassOrSmartProposal',
        'products',
      ]),
      /**
       * Makes sure that the char limit isn't exceeded, the button hasn't been clicked
       *
       * @returns {boolean}
       */
      canDecline() {
        return !this.limitExceeded && !this.btnDeclining;
      },
      /**
       * Determine which decline action to take depending on whether or not the feature flag is on
       * rff: uploadRenewalUploadEnhancementsEnabled - This computed property can be removed with the feature flag and it's logic moved into the template
       *
       * @returns {boolean}
       */
      declineAction() {
        if (this.uploadRenewalUploadEnhancementsEnabled) {
          return this.decliningAllProducts ? this.declineDocument : this.declineProduct;
        }

        return this.productsToDecline.length ? this.declineProduct : this.declineDocument;
      },
      /**
       * Evaluate whether the products that the user has selected to decline are all of the products available on the project.
       * If so, then we will be declining the Document/RFP as a whole instead of individual products.
       *
       * @returns {boolean}
       */
      decliningAllProducts() {
        // rff: uploadRenewalUploadEnhancementsEnabled - Remove this initial 'this.productsToDecline.length' check with feature flag
        return !this.productsToDecline.length || this.productsToDecline?.length === this.products.length;
      },
      /**
       * Evaluate whether user has selected more than one product to decline.
       *
       * @returns {boolean}
       */
      decliningMultipleProducts() {
        return this.productsToDecline?.length > 1;
      },
      /**
       * Changes the decline button template text depending on whether the user is declining
       * to quote on one or multiple products, versus an entire RFP
       *
       * @returns {string}
       */
      declineButtonText() {
        if (this.productsToDecline.length) {
          if (!this.uploadRenewalUploadEnhancementsEnabled) {
            // rff: uploadRenewalUploadEnhancementsEnabled - This whole if block can be removed with the feature flag
            const productType = isStopLossProduct(this.productsToDecline[0]) ? 'option' : 'product';

            return `Decline ${productType}`;
          } if (!this.decliningAllProducts) {
            const productType = this.decliningMultipleProducts ? 'products' : 'product';

            return `Decline ${productType}`;
          }
        }

        return 'Decline to Quote';
      },
      /**
       * creates an object that will be passed to the appropriate declineToQuote service method
       *
       * @returns {object}
       */
      declineReason() {
        const elaboration = this.userDefinedReason || 'No reason given';

        if (this.predefinedReason && this.predefinedReason !== 'Other') {
          return {
            declined_reason: this.predefinedReason,
            declined_reason_elaboration: null,
          };
        }

        return {
          declined_reason: 'Other',
          declined_reason_elaboration: elaboration,
        };
      },
      /**
       * options for each radio button in declining an RFP or product
       *
       * @returns {Array}
       */
      predefinedOptions() {
        return [
          'Rates Uncompetitive',
          'Non-preferred (or unfavorable) industry',
          'Size segment outside carrier min/max threshold',
          'Unable to meet plan design requirements',
          'Quoted (and lost) a particular group too frequently in the past',
          'Other',
        ];
      },
      /**
       * Evaluate the uploadRenewalUploadEnhancements feature flag.
       *
       * @returns {boolean}
       */
      uploadRenewalUploadEnhancementsEnabled() {
        return this.$ld.checkFlags(uploadRenewalUploadEnhancements);
      },
    },
    methods: {
      ...mapActions(useProductStore, ['updateProductState', 'setProducts']),
      ...mapActions(useProjectStore, ['setProposalDocument']),
      ...mapActions(useNotificationsStore, ['setAlertQuotesTab']),
      isStopLossProduct,
      /**
       * Confirmation that the user wants to decline to quote this proposal.
       */
      async declineDocument() {
        this.btnDeclining = true;

        try {
          const document = await DocumentServices.declineDocument(
            this.proposalDocumentId,
            this.declineReason,
          );

          this.setProducts(document.products);
          this.setProposalDocument(document);
          this.displayDeclineOrAllSubmittedDialog();
          this.sendAnalytics();
        } catch {
          this.$message({
            showClose: true,
            message: 'There was an error declining this proposal. Please try again.',
            type: 'error',
          });
        } finally {
          this.closeDeclineToQuoteDialog();
        }
      },
      /**
       * Call the product service to decline a product or multiple products
       */
      async declineProduct() {
        this.btnDeclining = true;

        try {
          await Promise.all(this.productsToDecline.map(async ({ id }) => {
            await this.updateProductState({
              productId: id,
              endpointState: 'decline',
              declinedReason: this.declineReason,
            });
          }));

          this.displayDeclineOrAllSubmittedDialog();

          this.sendAnalytics();
        } catch {
          this.$message({
            showClose: true,
            message: 'Not all of the products you selected were successfully declined.',
            type: 'error',
          });
        } finally {
          this.closeDeclineToQuoteDialog();
        }
      },
      /**
       * If all products are being declined or submitted, we show a dialog to that effect.
       * Otherwise, fire an action to trigger a 'successful decline' message.
       */
      displayDeclineOrAllSubmittedDialog() {
        if (this.isUploadRenewalRatePassOrSmartProposal) return;
        const allProductsDeclinedOrSubmitted = this.products.every((product) => ['finalized', 'declined'].includes(product.document_state));

        if (allProductsDeclinedOrSubmitted) {
          this.allProductsSubmittedDialogVisible = true;
        } else {
          this.setAlertQuotesTab({
            products: this.productsToDecline,
            actionType: 'declined',
            text: 'You have successfully declined to quote on all products.',
            type: 'success',
          });
        }
      },
      /**
       * Emit an event to close this decline to quote dialog
       */
      closeDeclineToQuoteDialog() {
        this.btnDeclining = false;
        if (this.isUploadRenewalRatePassOrSmartProposal) this.storeProductsToDecline = [];
        this.$emit('update:visible', false);
      },
      /**
       * If you click the 'x' make sure the dialog closes
       *
       * @param {Function} done
       */
      onBeforeClose(done) {
        this.btnDeclining = false;
        if (this.isUploadRenewalRatePassOrSmartProposal) this.storeProductsToDecline = [];
        done();
        this.$emit('update:visible', false);
      },
      /**
       * Sends event to segment
       */
      sendAnalytics() {
        trackSegmentEvent('Clicked Decline to Quote', segmentData());
      },
    },
  };
</script>

<style lang="scss" scoped>
  div {
    text-align: center;
  }

  img {
    width: 67px;
    height: 78px;
    margin: 0 auto;
  }

  p {
    margin: 23px 0;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    color: $tf-dark-gray;
  }

  p#decline-modal-info {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    padding: 0 20px;
  }

  p#reason-for-declining-label {
    text-align: left;
    color: $tf-dark-gray;
    font-size: 14px;
    margin: 10px 0;

    &.radio {
      padding-left: 20px;
    }

    span {
      color: $tf-medium-gray;
    }
  }

  #decline-reason-options {
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px;
  }

  :deep(.el-dialog__body) {
    margin-top: -17.5px;
    padding: 0 40px;
  }

  .btn-group {
    margin: 23px 0 45px;
  }

  :deep(.el-dialog .el-dialog__header) {
    border-bottom: 0;
  }

  :deep() .el-radio {
    text-align: left;
    color: $tf-dark-gray;
    white-space: normal;
    display: flex;

    &__label {
      padding-left: 12px;
    }

    &__input {
      align-self: center;
      margin-top: 3px;
    }

    + .el-radio {
      margin-left: 0;
      margin-top: 9px;
    }
  }
</style>
