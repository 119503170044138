<template>
  <div>
    <h3
      data-test="rate pass header"
      class="inner-header"
    >
      Select products eligible for rate pass
    </h3>
    <div id="product-list">
      <h3
        data-test="opportunitys products header"
      >
        Opportunity's products
      </h3>
      <ul data-test="product list">
        <ElCheckboxGroup v-model="checkedProducts">
          <li
            v-for="product in products"
            :key="product.id"
          >
            <ElCheckbox
              :value="selectedProducts.includes(product.id)"
              :label="product.id"
              :data-test="`${productLabel(product)} checkbox`"
              @change="triggerProductSelectionAnalyticsEvent($event, product.id)"
            >
              {{ productLabel(product) }}
            </ElCheckbox>
          </li>
        </ElCheckboxGroup>
      </ul>
    </div>
    <AppButton
      v-if="uploadEnhancementsEnabled"
      class="select-all-cta"
      size="text"
      data-test="select all products for rate pass"
      text="Select all"
      @click="selectAllProducts"
    />
    <div class="btn-group">
      <AppButton
        :is-disabled="!uploadEnhancementsEnabled && selectedProducts.length === 0"
        size="large"
        data-test="confirm rate pass products selected"
        @click="handleNextStep"
      >
        Next
      </AppButton>
    </div>
  </div>
</template>

<script>
  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
  // rff:uploadRenewalUploadEnhancements - Evaluate whether this component and it's test can be removed with ff
  /**
   * Select Product.
   *
   * @exports Modals/StartOrUpdateRenewalModal/ProductSelection
   */
  export default {
    name: 'ProductSelection',
    props: {
      products: {
        type: Array,
        default: () => [],
      },
      selectedProducts: {
        type: Array,
        default: () => [],
      },
      uploadEnhancementsEnabled: {
        type: Boolean,
        default: true,
      },
    },
    data: (vm) => ({
      checkedProducts: vm.selectedProducts,
    }),
    methods: {
      /**
       * Product label formatting
       *
       * @param {object} product
       * @param {string} product.project_product
       * @param {string} product.product_type_name
       * @returns {string}
       */
      productLabel({ project_product: projectProduct, product_type_name: name }) {
        const regex = new RegExp(`^${name}(.*)`, 'i');

        return `${name}${projectProduct.label.replace(regex, ' $1')}`.trim();
      },
      /**
       * Move the modal to the next step, either step three if no products selected or which opens the
       * "Rate Guarantees" template and closes the "Select Products" template. Then trigger an analytics call.
       */
      handleNextStep() {
        if (this.selectedProducts.length === 0) {
          this.$emit('updateStep', 'three');
        } else {
          this.$emit('updateStep', 'two');
          trackSegmentEvent('Rate pass modal continued', {
            product_ids: this.checkeddProducts,
          });
        }
      },
      /**
       * Selects all listed products.
       */
      selectAllProducts() {
        this.checkedProducts = this.products.map((product) => product.id);
        this.$emit('update:selectedProducts', this.checkedProducts);
      },
      /**
       * Trigger an analytics call if the product is selected in the "Select Products" modal.
       *
       * @param {boolean} checked
       */
      triggerProductSelectionAnalyticsEvent(checked) {
        if (checked) {
          trackSegmentEvent('Product selected from rate pass', {
            product_ids: this.selectedProducts,
          });
        }
        this.$emit('update:selectedProducts', this.checkedProducts);
      },
    },
  };
</script>
<style scoped lang="scss">
.inner-header {
  margin-bottom: 24px;
}

#product-list {
  border: 1px solid var(--tf-gray-medium);
  margin-bottom: 8px;

  h3 {
    padding: 8px 16px;
    border-bottom: 1px solid var(--tf-gray-medium);
    margin-bottom: 0;
    background: var(--tf-gray-light);
  }

  ul {
    li {
      padding: 8px 16px;
      border-bottom: 1px solid var(--tf-gray-medium);

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
