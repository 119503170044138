<template>
  <div>
    <YourQuotesWithProductTable
      v-if="isUploadRenewalRatePass || isSmartProposal"
    />
    <YourQuotesWithProductCards v-else />
  </div>
</template>

<script>
  import DocumentsService from '@/services/documents.js';

  import { captureException, addBreadcrumb } from '@sentry/vue';

  import { mapActions, mapState, mapWritableState } from 'pinia';
  import { useProductStore } from '@/stores/product.js';
  import { useProductTableStore } from '@/stores/productTable.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useCarrierInfoStore } from '@/stores/carrierInfo.js';

  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';

  import YourQuotesWithProductCards from '@/views/RfpOverview/YourQuotesWithProductCards.vue';
  import YourQuotesWithProductTable from '@/views/RfpOverview/YourQuotesWithProductTable.vue';

  /**
   * Project Overview Quotes Tab parent wrapper component. Only used to determine which YourQuotes component to render.
   * rff:uploadRenewalUploadEnhancements - Upon removal of this feature flag, this component will no longer be needed.
   *
   * @exports RfpOverview/YourQuotes
   */
  export default {
    name: 'YourQuotes',
    components: {
      YourQuotesWithProductCards,
      YourQuotesWithProductTable,
    },
    computed: {
      ...mapState(useProductStore, [
        'products',
        'isUploadRenewalRatePass',
        'isSmartProposal',
      ]),
      ...mapState(useCarrierInfoStore, ['id']),
      ...mapState(useProjectStore, ['proposalDocument', 'projectId', 'broker']),
      ...mapWritableState(useProductTableStore, ['proposalRateGuarantees']),
    },
    async mounted() {
      /**
       * Set up the proposalRateGuarantees object with basic data so it can reactively render.
       * Also retrieve the RG value for completed products and put those in the store as well.
       */
      this.products.forEach(({ id, product_type_name: name }) => {
        this.proposalRateGuarantees = {
          ...this.proposalRateGuarantees,
          [id]: {
            id,
            name,
            rate_guarantee: '',
            error: false,
          },
        };
      });

      await this.getProductRateGuarantees();
      await this.getProposalRateGuarantees();

      trackSegmentEvent('Visited Your Quotes page', {
        broker_name: this.broker.name,
        broker_id: this.broker.id,
        project_id: this.projectId,
      });
    },
    methods: {
      ...mapActions(useProductTableStore, ['getProductRateGuarantees']),
      /**
       * Gets Rate Guarantees.
       */
      async getProposalRateGuarantees() {
        try {
          const data = await DocumentsService.getRateGuaranteeValues(this.proposalDocument.id);

          this.products.forEach(({ id }) => {
            try {
              // Once error is diagnosed in Sentry, add optional chaning to data check: data?.[id]?.rate_guarantee.toString() || ''
              this.proposalRateGuarantees[id].rate_guarantee = data[id].rate_guarantee?.toString() || '';
            } catch (error) {
              addBreadcrumb({
                message: 'Rate guarantee not in response',
                category: 'error',
                level: 'error',
                data: {
                  data,
                  products: this.products,
                  error,
                },
              });
              captureException(error);
            }
          });
        } catch (error) {
          addBreadcrumb({
            message: 'Error getting rate guarantees',
            category: 'error',
            level: 'error',
            data: {
              error,
            },
          });
          captureException(error);
          this.$message({
            duration: 3000,
            message: 'There was an error getting rate guarantees.',
            showClose: true,
            type: 'error',
          });
        }
      },
    },
  };
</script>
